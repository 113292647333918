import { CommonModule } from '@angular/common';
import { Component, signal, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { Connect } from '../../../../classes/connect';
import { ApiResponse } from '../../../../interfaces/api-response';
import { PopupDialogService } from '../../../../services/popup-dialog.service';
import { ArticlesService } from '../../../../services/articles.service';
import { ArticleFile } from '../../../../interfaces/image';
import { UploadImageService } from '../../../../services/upload-image.service';
import { ConnectServerService } from '../../../../services/connect-server.service';
import { SelectOptions } from '../../interfaces/select-options';
import { CheckFormValidityService } from '../../../../services/check-form-validity.service';

@Component({
  selector: 'app-general-info',
  standalone: true,
  imports: [
    MatExpansionModule,
    ReactiveFormsModule,
    CommonModule
  ],
  templateUrl: './general-info.component.html',
  styleUrl: './general-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralInfoComponent {

  @Output() buttonClicked = new EventEmitter<any>();
  @Input() listType: SelectOptions[] = [];
  @Input() idarticle: number = 0;

  urlServerLaraApiMultimedia = Connect.urlServerLaraApiMultimedia;
  saved: boolean = false;

  year = new Date().getFullYear();
  submitted: boolean = false;

  files: ArticleFile[] = [];
  currentImg!: number;
  selectedFiles: File[] = [];

  readonly panelOpenState = signal(false);

  generalForm = new FormGroup({
    title: new FormControl<string | null>(null, Validators.required),
    storage_place: new FormControl<string | null>(null, Validators.required),
    article_type: new FormControl<number | null>(null, Validators.required),
    room: new FormControl<string | null>(null, Validators.required),
    floor: new FormControl<string | null>(null, Validators.required),
    rack: new FormControl<string | null>(null, Validators.required),
    shelf: new FormControl<string | null>(null, Validators.required),
    case: new FormControl<string | null>(null, Validators.required),
    public: new FormControl<number>(0, Validators.required),
    public_year: new FormControl<number | null>(null)
  })

  constructor(private router: Router, private popupDialogService: PopupDialogService, private articlesService: ArticlesService,
    private uploadImage: UploadImageService, private connectServerService: ConnectServerService,
    private checkFormValidityService: CheckFormValidityService) { }

  ngOnInit(): void {
    if(this.idarticle > 0) {
      this.getGeneralInfo();
    }
    else {
      this.panelOpenState.set(true);
    }
  }

  save() {
    this.submitted = true;
    if(this.generalForm.valid) {
      if (this.idarticle != 0) {
        this.setGeneralInfo();
      }
      else {
        const formObjGeneral = this.generalForm.getRawValue();
        this.buttonClicked.emit(formObjGeneral);
      }
    }
  }

  changeCover(id: number) {
    this.uploadImage.changeCover(id).
      subscribe((val: ApiResponse<null>) => {
        this.articlesService.getFilesServer(this.idarticle!).subscribe((val: ApiResponse<{ listFiles: ArticleFile[] }>) => {
          if (val.data.listFiles) {
            this.files = val.data.listFiles;
          }
        })
      })
  }

  getGeneralInfo() {
    this.connectServerService.getRequest('generalInfo', { idarticle: this.idarticle })
      .subscribe(
        (val: ApiResponse<{ generalForm: any }>) => {
          this.generalForm.patchValue(val.data.generalForm);
          this.saved = this.checkFormValidityService.areAllRequiredFieldsFilled(this.generalForm);
          if(this.saved) {
            this.panelOpenState.set(false);
          }
          else {
            this.panelOpenState.set(true);
          }
        }
      )
  }

  setGeneralInfo() {
    const formValues = this.generalForm.getRawValue();
    this.connectServerService.postRequest('updateGeneralInfo',
      { idarticle: this.idarticle, infoGeneral: formValues })
      .subscribe(
        (val: ApiResponse<null>) => {
          this.popupDialogService.popupDialog(val);
          this.getGeneralInfo();
        }
      )
  }

  deleteImg(id: number) {
    const obj_request: ApiResponse<any> = {
      code: 244,
      data: {},
      title: 'Info',
      message: 'Sei sicuro di voler cancellare l\'immagine?',
      obj_dialog: {
        disableClose: 0,
        obj_buttonAction:
        {
          action: 1,
          action_type: 2,
          label: 'Cancella',
          run_function: () => this.deleteImgFromServer(id)
        }
      }
    }
    this.popupDialogService.popupDialog(obj_request);
  }

  private deleteImgFromServer(id: number) {
    this.uploadImage.deleteFile(id).subscribe((val: ApiResponse<null>) => {
      this.articlesService.getFilesServer(this.idarticle!).subscribe((val: ApiResponse<{ listFiles: ArticleFile[] }>) => {
        if (val.data.listFiles) {
          this.files = val.data.listFiles;
          this.currentImg = this.files.find((val: ArticleFile) => { return val.cover === 1 })?.id!;
        }
      })
    })
  }

  /**
   * Quando si seleziona i file
   * @param event
   */
  onFileSelected(event: Event): void {
    const maxFiles = 10;
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.selectedFiles = Array.from(input.files);
      if (this.selectedFiles.length > maxFiles) {
        const obj_request: ApiResponse<any> = {
          code: 240,
          data: {},
          title: 'Warning',
          message: 'Non puoi importare più di ' + maxFiles + ' files. Seleziona meno immagini e riprova.'
        }
        this.popupDialogService.popupDialog(obj_request);
      } else {
        this.uploadFilesServer();
      }

    }
  }
  /**
   * Reset la selezione dei file quando importato
   */
  private resetFileInput() {
    const fileInput = document.getElementById('fileUpload') as HTMLInputElement;
    fileInput.value = '';
    this.selectedFiles = [];
  }

  private uploadFilesServer() {
    const formData = new FormData();
    formData.append('id', String(this.idarticle));
    this.selectedFiles.forEach((file, index) => {
      formData.append(`files[]`, file);
    });
    const upload$ = this.connectServerService
      .postRequest<ApiResponse<null>>('uploadFilesArticle', formData);
    upload$
      .subscribe(
        (res: ApiResponse<null>) => {
          this.popupDialogService.popupDialog(res);
          this.resetFileInput();
          this.articlesService.getFilesServer(this.idarticle!).
            subscribe(
              (val: ApiResponse<{ listFiles: ArticleFile[] }>) => {
                this.files = val.data.listFiles;
                this.currentImg = this.files.find((val: ArticleFile) => { return val.cover === 1 })?.id!;
              }
            );
        }
      );
  }

  visualizeImg(id: number) {
    this.currentImg = id;
  }

  enableDisablePublication() {
    if (this.generalForm.get('public')!.value == 0) {
      this.generalForm.get('public_year')?.disable();
      this.generalForm.get('public_year')?.setValue(null);
      this.generalForm.get('public_year')?.clearValidators()
    }
    else {
      this.generalForm.get('public_year')?.enable();
      this.generalForm.get('public_year')?.setValue(this.year);
      this.generalForm.get('public_year')?.addValidators(Validators.required);
    }
  }

  isCorrectPublicYear() {
    if (/^[0-9]+$/.test(this.generalForm.get('public_year')!.value!.toString())) {
      if (this.generalForm.get('public_year')?.value! < this.year || this.generalForm.get('public_year')?.value! > 9999) {
        this.generalForm.get('public_year')?.setErrors({invalidYear: true});
      }
      else this.generalForm.get('public_year')?.setErrors(null);
    }
    else this.generalForm.get('public_year')?.setErrors({invalidYear: true});
  }
}
