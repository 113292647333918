import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ApiResponse } from '../../interfaces/api-response';
import { Category, SubCategory } from '../../interfaces/article';
import { Connect } from '../../classes/connect';
import { ConnectServerService } from '../../services/connect-server.service';

@Component({
  selector: 'app-import-articles-from-pdf',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  templateUrl: './import-articles-from-pdf.component.html',
  styleUrl: './import-articles-from-pdf.component.scss'
})
export class ImportArticlesFromPdfComponent {
  categoryOptions: Category[] = [];
  subcategoryOptions: SubCategory[] = [];
  folders: string[] = [];
  year = new Date().getFullYear();

  importForm = new FormGroup({
    category: new FormControl<null | Category>(null, Validators.required),
    subCategory: new FormControl<null | SubCategory>(null),
    location: new FormControl<string | null>(null),
    public: new FormControl<number>(0),
    room: new FormControl<string | null>(null, Validators.required),
    floor: new FormControl<string | null>(null, Validators.required),
    rack: new FormControl<string | null>(null, Validators.required),
    case: new FormControl<string | null>(null, Validators.required),
    code: new FormControl<string | null>(null, Validators.required),
    public_year: new FormControl<number | null>({ value: null, disabled: true }),
    folder: new FormControl<number | null>(null, Validators.required),
  })

  constructor(private connectServerService: ConnectServerService) {
    this.getCategories();
    this.formLogic();
  }

  private formLogic() {
    this.importForm.get('category')?.valueChanges.subscribe(
      (val: Category | null) => {
        if (val?.id) {
          this.getSubcategories(val.id);
        }
      }
    )
  }

  private getCategories() {
    this.connectServerService.getRequest('categories', {})
      .subscribe(
        (val: ApiResponse<{ categories: Category[] }>) => {
          this.categoryOptions = val.data.categories;
        }
      )
  }

  private getSubcategories(idcategory: number): void {
    this.connectServerService.getRequest<ApiResponse<{ subCategories: SubCategory[] }>>
      ('subCategories', { id: idcategory })
      .subscribe(
        (val: ApiResponse<{ subCategories: SubCategory[] }>) => {
          if (val.data && val.data.subCategories) {
            this.subcategoryOptions = val.data.subCategories;
          }
        }
      )
  }

  enableDisablePublication() {
    if (this.importForm.get('public')!.value == 0) {
      this.importForm.get('public_year')?.disable();
      this.importForm.get('public_year')?.setValue(null);
      this.importForm.get('public_year')?.clearValidators()
    }
    else {
      this.importForm.get('public_year')?.enable();
      this.importForm.get('public_year')?.setValue(this.year);
      this.importForm.get('public_year')?.addValidators(Validators.required);
    }
  }

  isCorrectPublicYear() {
    if (/^[0-9]+$/.test(this.importForm.get('public_year')!.value!.toString())) {
      if (this.importForm.get('public_year')?.value! < this.year || this.importForm.get('public_year')?.value! > 9999) {
        this.importForm.get('public_year')?.setErrors({invalidYear: true});
      }
      else this.importForm.get('public_year')?.setErrors(null);
    }
    else this.importForm.get('public_year')?.setErrors({invalidYear: true});
  }

  insertArticles() {

  }
}
