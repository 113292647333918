<div class="container-fluid mt-5 p-0 p-sm-2 p-md-3 p-xl-5" style="height: 80%;">
    <div class="row justify-content-center align-items-center mt-5 p-1 p-sm-2 p-md-3 p-lg-5">
        <div class="col-4 mt-5 d-flex justify-content-center align-items-center">
            <div class="lock"></div>
        </div>
        <div class="col-12 text-center">
            <div class="message">
                <h1>L'accesso a questa pagina è limitato</h1>
                <p>Effettua il login se hai i permessi per accedere.</p>
            </div>
        </div>
    </div>
</div>