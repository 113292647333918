import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-signup',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule
  ],
  templateUrl: './signup.component.html',
  styleUrl: './signup.component.scss'
})
export class SignupComponent {

  type1: string = 'password'
  type2: string = 'password'
  toggled1: boolean = true;
  toggled2: boolean = true;

  validPassword: boolean = true;
  equalPassword: boolean = true;
  errorMessage: boolean = false;

  signupForm = new FormGroup({
    name: new FormControl<string | null>(null, Validators.required),
    surname: new FormControl<string | null>(null, Validators.required),
    email: new FormControl<string | null>(null, Validators.email),
    description: new FormControl<string | null>(null, Validators.required),
    password: new FormControl<string | null>(null, Validators.required),
    password_confirmation: new FormControl<string | null>(null, Validators.required),
  })

  constructor(private authService: AuthService) { }

  
  seePassword(id: string) {
    if (id === 'password1') {
      if (this.type1 === 'password') {
        this.type1 = 'text';
        this.toggled1 = false;
      }
      else {
        this.type1 = 'password';
        this.toggled1 = true;
      }
    }
    else if (id === 'password2') {
      if (this.type2 === 'password') {
        this.type2 = 'text';
        this.toggled2 = false;
      }
      else {
        this.type2 = 'password';
        this.toggled2 = true;
      }
    }
  }

  checkSignupForm() {
    const name = this.signupForm.get("name")?.value;
    const surname = this.signupForm.get("surname")?.value;

    if (name != '' && name != null) {
      this.signupForm.get("name")?.setErrors(null);
    }
    else {
      this.signupForm.get("name")?.setErrors({ invalid: true });
    }

    if (surname != '' && surname != null) {
      this.signupForm.get("surname")?.setErrors(null);
    }
    else {
      this.signupForm.get("surname")?.setErrors({ invalid: true });
    }
  }


  onSubmit() {
    const name = this.signupForm.get('name')!.value as string;
    const email = this.signupForm.get('email')!.value as string;
    const description = this.signupForm.get('description')!.value as string;
    const password = this.signupForm.get('password')!.value as string;
    this.authService.registerUser(name, email, description, password).subscribe((esito: any) => { console.log('esito', esito) });
    console.log(this.signupForm.value);

    //else {error}
  }

  checkPassword() {
    const password = this.signupForm.get('password')?.value;
    const passwordRepeat = this.signupForm.get('password_confirmation')?.value;
    const regex = /^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":|<>])(?=.{8,})/;
    if(regex.test(password!)) {
      if(password === passwordRepeat) {
        this.equalPassword = true;
        this.validPassword = true;
        this.errorMessage = false;
        this.signupForm.get("password")?.setErrors(null);
        this.signupForm.get("password_confirmation")?.setErrors(null);
        return true;
      }
      else {
        this.equalPassword = false;
        this.validPassword = true;
        this.errorMessage = true;
        this.signupForm.get("password")?.setErrors({invalid: true});
        this.signupForm.get("password_confirmation")?.setErrors({invalid: true});
        return false;
      }
    }
    else {
      this.equalPassword = true;
      this.validPassword = false;
      this.errorMessage = true;
      this.signupForm.get("password")?.setErrors({invalid: true});
      this.signupForm.get("password_confirmation")?.setErrors(null);
      return false;
    }
  }

  checkEmail() {
    const email_val = this.signupForm.get('email')?.value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailRegex.test(email_val!)) {
      this.signupForm.get('email')?.setErrors(null); 
    }
    else {
      this.signupForm.get('email')?.setErrors({invalid: true});
    }
  }

}
