import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { Connect } from '../../../../classes/connect';
import { ArticleFile } from '../../../../interfaces/image';
import { Article } from '../../../../interfaces/article';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ArticlesService } from '../../../../services/articles.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ApiResponse } from '../../../../interfaces/api-response';

@Component({
  selector: 'app-img-display',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './img-display.component.html',
  styleUrl: './img-display.component.scss'
})
export class ImgDisplayComponent {
  @ViewChild('imageContainer') imageContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('image') image!: ElementRef<HTMLImageElement>;

  imageId: number;

  urlServerLaraApiMultimedia = Connect.urlServerLaraApiMultimedia;

  currentSrc: string | null = null;

  files: ArticleFile[] = [];
  totalFiles: ArticleFile[] = [];
  article!: Article | null;
  //imageId!: number;
  activeImageIndex: number = 0;

  extension: string;

  zoomLevel: number = 1;
  maxZoom: number = 2; // Limite massimo dello zoom
  minZoom: number = 1; // Limite minimo dello zoom

  constructor(@Inject(MAT_DIALOG_DATA) public data: { article: ArticleFile }, private articlesService: ArticlesService, private route: ActivatedRoute, private router: Router,
    private dialogRef: MatDialogRef<ImgDisplayComponent>) {
    this.currentSrc = data.article.src;
    this.extension = data.article.ext;
    this.imageId = data.article.id;
  }

  ngOnInit() {
    // console.log(this.imageId)
    //this.route.params.subscribe(
    //  (params) => {
    //console.error('params: ', params['name'])
    //  this.imageId = parseInt(params['id']);
    this.articlesService.getFilesFromImage(this.imageId!).
      subscribe(
        (val: ApiResponse<{ listFilesFromImage: ArticleFile[] }>) => {
          this.totalFiles = val.data.listFilesFromImage;
          this.removePdf();
          this.setCurrentImg();
          this.updateButtons();
        })
  }

  removePdf() {
    this.files = this.totalFiles.filter(file => file.ext !== 'pdf');
    console.log(this.files)
  }

  navigate() {
    this.router.navigate(['/archivio/scheda', this.files[0].refidarticle])
  }

  setCurrentImg() {
    this.currentSrc = this.files.find((val: ArticleFile) => {
      return val.id === this.imageId
    })?.src!;
    this.activeImageIndex = this.files.findIndex((val: ArticleFile) => {
      return val.id === this.imageId
    });
    console.log("Index: ", this.activeImageIndex);
  }

  nextPage() {
    if (this.activeImageIndex < this.files.length - 1) {
      this.activeImageIndex++;
      this.currentSrc = this.files[this.activeImageIndex].src;
    }
    this.updateButtons();
  }

  previousPage() {
    if (this.activeImageIndex > 0) {
      this.activeImageIndex--;
      this.currentSrc = this.files[this.activeImageIndex].src;
    }
    this.updateButtons();
  }

  updateButtons() {
    this.disableNextButton();
    this.disablePrevButton();
  }

  disableNextButton() {
    const button = document.getElementById('nextBtn') as HTMLButtonElement;
    if (this.activeImageIndex + 1 >= this.files.length) {
      // console.log('disabilita next');
      button.disabled = true;
    } else {
      // console.log('abilita next')
      button.disabled = false;
    }
  }

  disablePrevButton() {
    const button = document.getElementById('prevBtn') as HTMLButtonElement;
    if (this.activeImageIndex <= 0) {
      // console.log('disabilita prev');
      button.disabled = true;
    } else {
      button.disabled = false;
    }
  }

  zoomIn(): void {
    if (this.zoomLevel < this.maxZoom) {
      this.zoomLevel += 0.2;
      //this.adjustContainerSize();
    }
  }

  zoomOut(): void {
    if (this.zoomLevel > this.minZoom) {
      this.zoomLevel -= 0.2;
      //this.adjustContainerSize();
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  adjustContainerSize(): void {
    const container = this.imageContainer.nativeElement;
    const image = this.image.nativeElement;
  
    // Ottieni le dimensioni dello schermo
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
  
    // Ottieni le dimensioni scalate dell'immagine
    const scaledWidth = image.offsetWidth * this.zoomLevel;
    const scaledHeight = image.offsetWidth * this.zoomLevel;
  
    // Ottieni la larghezza attuale del contenitore
    const containerStyles = getComputedStyle(container);
    const currentWidth = parseFloat(containerStyles.width);
    const currentHeight = parseFloat(containerStyles.height);
  
    // Se l'immagine scalata rientra nello schermo, espandi il contenitore
    if (scaledWidth <= screenWidth) {
      container.style.width = `${scaledWidth}px`;
      container.style.height = `${scaledHeight}px`;
      container.style.overflow = 'hidden';
    } else {
      // Attiva lo scroll solo quando l'immagine supera lo schermo
      container.style.width = `${screenWidth}px`;
      container.style.height = `${screenHeight}px`;
      container.style.overflow = 'auto';
    }
  
    console.log('Container Width:', currentWidth);
    console.log('Container Height:', currentHeight);
  }
}
