import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function cityValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value || (typeof value === 'object' && 'id' in value && 'name' in value)) {
      return null; // Valore valido
    }
    return { invalidCity: true }; // Valore non valido
  };
}