<div class="container-fluid p-1 p-md-2 p-lg-3" style="min-height: 82%; min-width: 350px;">
    <div class="row p-2 justify-content-center align-items-center text-center"
        style="margin-left: 0 !important; margin-right: 0 !important;">
        <div class="col-12 mt-2" style="position: relative;">
            <img src="assets/img/contactBar.png" class="d-none d-lg-block img-flex mx-auto"
                style="width: 90%; height: auto;">
            <span class="d-none d-lg-block"
                style="font-weight: bold; color: white; font-size: x-large; position: absolute; top: 63%; left: 38%;">
                GESTIONE ACCESSO UTENTI
            </span>
        </div>
        <div class="col-8 text-center">
            <div class="d-lg-none" style="background-color: #1E73BE; border-radius: 15px;">
                <span style="font-weight: bold; font-size: x-large; color: white;">
                    GESTIONE ACCESSO UTENTI
                </span>
            </div>
        </div>

        <div class="col-12 col-md-11 col-lg-10 col-xxl-8 mt-4 p-0">
            <form [formGroup]="usersForm">
                <div formArrayName="users">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Email</th>
                                <th>Autorizzazione</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- Ciclo sui controlli 'authorizations', che contengono gli utenti -->
                            <tr *ngFor="let user of users.controls; let i = index" [formGroupName]="i">
                                <!-- Nome dell'utente -->
                                <td>
                                    {{ user.get('nickname')?.value }}
                                </td>

                                <!-- Email dell'utente -->
                                <td>
                                    {{ user.get('email')?.value }}
                                </td>

                                <!-- Autorizzazione dell'utente -->
                                <td>
                                    <input class="form-check-input" type="checkbox" formControlName="authorization">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </form>
        </div>
    </div>

    <div class="row justify-content-center align-items-center p-2">
        <div class="col-12 col-md-6 col-lg-4 text-center">
            <button class="btn btn-outline-dark" (click)="save()">
                Salva
            </button>
        </div>
    </div>
</div>