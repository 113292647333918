<div class="container-fluid p-1 p-md-2 p-lg-3" style="min-height: 80%; min-width: 350px; max-width: 1400px;">
    <div class="row p-1 p-sm-2 justify-content-center align-items-center text-center" style="margin-left: 0 !important; margin-right: 0 !important;">
        <div class="col-12" style="position: relative;">
            <img src="assets/img/contactBar.png" class="d-none d-lg-block img-flex mx-auto"
                style="width: 90%; height: auto;">
            <span class="d-none d-lg-block" style="font-weight: bold; color: white; font-size: x-large;
                    position: absolute; top: 63%; left: 38%;">
                REIMPOSTA PASSWORD
            </span>
        </div>
        <div class="col-12 col-md-8 text-center">
            <div class="d-lg-none" style="background-color: #1E73BE; border-radius: 15px;">
                <span style="font-weight: bold; font-size: x-large; color: white;">
                    REIMPOSTA PASSWORD
                </span>
            </div>
        </div>
    </div>

    <div class="col-12 text-center p-1 p-md-2 p-lg-3">
        <span>Inserisci una nuova password</span><br>
        <span style="font-size: small;">Lunghezza minima 8 caratteri. Richiesta almeno una lettera maiuscola e un carattere speciale !#$%^&*(),.?":|></span>
    </div>


    <div class="row justify-content-center align-items-center" style="margin-left: 0 !important; margin-right: 0 !important;">
        <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xxl-4 mt-4">
            <div class="card p-1 p-sm-2 p-md-3 p-lg-4">
                <div class="card-body">
                    <form [formGroup]="newPswForm" class="has-validation" style="margin: 0 auto;">
                        <div class="form-group">
                            <label for="password" style="font-weight: bold;">Password</label>
                            <div class="input-container input-group" style="position: relative;">
                                <input [type]="type1" name="password" id="password" formControlName="password"
                                    class="form-control" placeholder="" [ngClass]="{'error': errorMessage}" required
                                    (input)="checkPsw()" [class.is-invalid]="newPswForm.get('password')?.invalid 
                                    && (newPswForm.get('password')?.touched || newPswForm.get('password')?.dirty)">
                                <button class="btn btn-outline-dark" (click)="seePassword('password')">
                                    <i class="bi bi-eye" [ngClass]="{'bi-eye': !toggled1, 'bi-eye-slash': toggled1}"
                                        id="togglePassword"></i>
                                </button>
                            </div>
                            <div *ngIf="errorMessage && !validPassword" class="mt-1" style="color: rgb(255, 0, 0);">
                                <span style="font-size: small;">La password inserita non è valida</span>
                            </div>

                            <label class="mt-4" for="passwordRepeat" style="font-weight: bold;">Ripeti Password</label>
                            <div class="input-container input-group" style="position: relative;">
                                <input [type]="type2" name="passwordRepeat" id="passwordRepeat"
                                    formControlName="passwordRepeat" class="form-control" placeholder=""
                                    [ngClass]="{'error': errorMessage}" required (input)="checkPsw()"
                                    [class.is-invalid]="newPswForm.get('passwordRepeat')?.invalid
                                    && (newPswForm.get('password')?.touched || newPswForm.get('password')?.dirty)">
                                <button class="btn btn-outline-dark" (click)="seePassword('passwordRepeat')">
                                    <i class="bi" [ngClass]="{'bi-eye': !toggled2, 'bi-eye-slash': toggled2}"
                                        id="togglePassword"></i>
                                </button>
                            </div>
                            <div *ngIf="errorMessage && !equalPassword" class="mt-1" style="color: rgb(255, 0, 0);">
                                <span style="font-size: small;">Le password inserite non combaciano</span>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-footer text-center mt-2" style="background-color: white;">
                    <button class="btn btn-outline-dark mt-2" (click)="send()"
                        [disabled]="newPswForm.invalid">Reimposta</button>
                </div>
            </div>
        </div>
    </div>
</div>