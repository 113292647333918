import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Input, ViewChild } from '@angular/core';
import { ArticlesService } from '../../../../services/articles.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Article } from '../../../../interfaces/article';
import { ApiResponse } from '../../../../interfaces/api-response';
import { ArticleFile } from '../../../../interfaces/image';
import { Connect } from '../../../../classes/connect';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-image-viewer',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './image-viewer.component.html',
  styleUrl: './image-viewer.component.scss'
})
export class ImageViewerComponent {

  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @ViewChild('image') image!: ElementRef;

  imageId: number;

  urlServerLaraApiMultimedia = Connect.urlServerLaraApiMultimedia;

  zoomLevel: number = 1;
  zoomStep: number = 0.1;
  maxZoom: number = 2;
  minZoom: number = 0.3;
  isSmall: boolean = false;

  currentSrc: string | null = null;

  files: ArticleFile[] = [];
  totalFiles: ArticleFile[] = [];
  article!: Article | null;
  //imageId!: number;
  activeImageIndex: number = 0;

  extension: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {article: ArticleFile}, private articlesService: ArticlesService, private route: ActivatedRoute, private router: Router, private breakpointObserver: BreakpointObserver,
  private dialogRef: MatDialogRef<ImageViewerComponent>) {
    //this.isImage = this.checkIfImage(data);
    this.currentSrc = data.article.src;
    this.extension = data.article.ext;
    this.imageId = data.article.id;
  }

  ngOnInit() {
    // console.log(this.imageId)
    this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.Handset])
      .subscribe(result => {
        this.isSmall = result.matches;
      });
    //this.route.params.subscribe(
    //  (params) => {
    //console.error('params: ', params['name'])
    //  this.imageId = parseInt(params['id']);
    this.articlesService.getFilesFromImage(this.imageId!).
      subscribe(
        (val: ApiResponse<{ listFilesFromImage: ArticleFile[] }>) => {
          this.totalFiles = val.data.listFilesFromImage;
          this.removePdf();
          this.setCurrentImg();
          this.updateButtons();
        })
  }

  removePdf() {
    this.files = this.totalFiles.filter(file => file.ext !== 'pdf');
    console.log(this.files)
  }

  ngAfterViewInit() {
    this.adjustRowHeight();
  }

  navigate() {
    this.router.navigate(['/archivio/scheda', this.files[0].refidarticle])
  }

  setCurrentImg() {
    this.currentSrc = this.files.find((val: ArticleFile) => {
      return val.id === this.imageId
    })?.src!;
    this.activeImageIndex = this.files.findIndex((val: ArticleFile) => {
      return val.id === this.imageId
    });
    console.log("Index: ", this.activeImageIndex);
  }

  nextPage() {
    if (this.activeImageIndex < this.files.length - 1) {
      this.activeImageIndex++;
      this.currentSrc = this.files[this.activeImageIndex].src;
    }
    this.updateButtons();
  }

  previousPage() {
    if (this.activeImageIndex > 0) {
      this.activeImageIndex--;
      this.currentSrc = this.files[this.activeImageIndex].src;
    }
    this.updateButtons();
  }

  updateButtons() {
    this.disableNextButton();
    this.disablePrevButton();
  }

  disableNextButton() {
    const button = document.getElementById('nextBtn') as HTMLButtonElement;
    if (this.activeImageIndex + 1 >= this.files.length) {
      // console.log('disabilita next');
      button.disabled = true;
    } else {
      // console.log('abilita next')
      button.disabled = false;
    }
  }

  disablePrevButton() {
    const button = document.getElementById('prevBtn') as HTMLButtonElement;
    if (this.activeImageIndex <= 0) {
      // console.log('disabilita prev');
      button.disabled = true;
    } else {
      button.disabled = false;
    }
  }

  zoomIn() {
    if (this.zoomLevel < this.maxZoom) {
      this.zoomLevel += this.zoomStep;
      this.adjustRowHeight();
    }
  }

  zoomOut() {
    if (this.zoomLevel > this.minZoom) {
      this.zoomLevel -= this.zoomStep;
      this.adjustRowHeight();
    }
  }

  adjustRowHeight() {
    if (this.image && this.image.nativeElement) {
      const naturalHeight = this.image.nativeElement.naturalHeight;
      const scaledHeight = naturalHeight * this.zoomLevel;
      const maxHeight = window.innerHeight - 160;

      // Imposta l'altezza della riga in base all'altezza dell'immagine scalata, limitata all'altezza della finestra
      this.scrollContainer.nativeElement.style.height = Math.min(scaledHeight, maxHeight) + 'px';
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}


