import { inject, Injectable } from '@angular/core';
import { ConnectServerService } from '../../../../services/connect-server.service';
import { Observable } from 'rxjs';
import { Connect } from '../../../../classes/connect';

@Injectable({
  providedIn: 'root'
})
export class FolderService {
  private readonly connectServerService = inject(ConnectServerService);

  constructor() { }

  getFolderContents(archive_type: number, path: string = ''): Observable<any> {
    const path_send = path ? path : '';
    return this.connectServerService.getRequest('folderContents',
      {
        path: path_send,
        archive_type: archive_type
      });
  }
}
