import { inject, Injectable } from '@angular/core';
import { ApiResponse, ObjButtonPopup } from '../interfaces/api-response';
import { MatDialog } from '@angular/material/dialog';
import { PopupDialogComponent } from '../components/popup-dialog/popup-dialog.component';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PopupDialogService {

  constructor(public dialog: MatDialog) { }
  readonly router = inject(Router);
  popupDialog(obj_val: ApiResponse<any>){
    const dialogRef = this.dialog.open(PopupDialogComponent, {
      panelClass: 'modal_popup',
      data: {
        obj: obj_val
      }
    });

    dialogRef.afterClosed().subscribe(
      (result: ObjButtonPopup) => {
        // console.log('Dialog result:', result);
        if (result && result.action === 1) {
          if (result.action_type === 1) {
            const array_router = [result.urlfront];
            if (result.urlparam && result.urlparam != null) {
              array_router.push(result.urlparam);
            }
            this.router.navigate(array_router);
          } else if (result.action_type === 2) {
            if (typeof result.run_function === 'function') {
              result.run_function();
            }
          }
        }
        // console.log(`Dialog result: ${result}`);
      });
  }
}
