import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
  selector: '[appInViewport]',
  standalone: true
})
export class InViewportDirective {

  private observer: IntersectionObserver;

  @HostBinding('class.visible') isVisible = false;

  constructor(private el: ElementRef) {
    // Inizializza l'IntersectionObserver nel costruttore
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.isVisible = true;
          this.observer.unobserve(this.el.nativeElement); // Interrompe l'osservazione
        }
      });
    });
  }

  ngOnInit(): void {
    // Inizia l'osservazione dell'elemento
    this.observer.observe(this.el.nativeElement);
  }

  ngOnDestroy(): void {
    // Disconnetti l'osservatore quando la direttiva viene distrutta
    this.observer.disconnect();
  }

}
