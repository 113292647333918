import { environment } from "../../environments/environment.development";

export class Connect {
    static urlServerLaraApi = environment.urlserver.laravelApi;
    static urlServerLaraApiMisericordia = environment.urlserver.laravelApiMisericordia;
    static urlServerLaraApiMultimedia = environment.urlserver.laravelApiMultimedia;
    static urlServerLara = environment.urlserver.laravel;
    static sizeFileExcel = 20000001;
    static str_sizeFileExcel = '20 MB';
}
