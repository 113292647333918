import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CheckFormValidityService {

  constructor() {}

  /**
   * Verifica in modo ricorsivo se tutti i campi obbligatori sono compilati.
   * @param control Il controllo da validare (può essere FormGroup, FormArray o FormControl)
   * @returns true se tutti i campi obbligatori sono compilati, false altrimenti
   */
  public areAllRequiredFieldsFilled(control: AbstractControl): boolean {
    if (control instanceof FormGroup) {
      // Controlla ricorsivamente ogni controllo nel FormGroup
      return Object.values(control.controls).every(c => this.areAllRequiredFieldsFilled(c));
    } else if (control instanceof FormArray) {
      // Controlla ricorsivamente ogni controllo nel FormArray
      return control.controls.every(c => this.areAllRequiredFieldsFilled(c));
    } else {
      // Se è un FormControl, verifica la validità
      return !control.invalid || !control.hasValidator(Validators.required);
    }
  }
}
