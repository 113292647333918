<div class="container-fluid p-1 p-md-2 p-lg-3" style="min-height: 84%; min-width: 350px; max-width: 1400px;">
    <div class="row p-1 p-sm-2 justify-content-center align-items-center text-center" style="margin-left: 0 !important; margin-right: 0 !important;">
        <div class="col-12" style="position: relative;">
            <img src="assets/img/contactBar.png" class="d-none d-lg-block img-flex mx-auto"
                style="width: 90%; height: auto;">
            <span class="d-none d-lg-block" style="font-weight: bold; color: white; font-size: x-large;
                    position: absolute; top: 63%; left: 47%;">
                LOGIN
            </span>
        </div>
        <div class="col-8 text-center">
            <div class="d-lg-none" style="background-color: #1E73BE; border-radius: 15px;">
                <span style="font-weight: bold; font-size: x-large; color: white;">
                    LOGIN
                </span>
            </div>
        </div>
    </div>

    <div class="row mt-4 justify-content-center align-items-center" style="margin-left: 0 !important; margin-right: 0 !important;">
        <div class="col-11 col-sm-10 col-md-8 col-lg-6 col-xxl-4">
            <div class="card p-1 p-md-2">
                <div class="card-body">
                    <form [formGroup]="loginForm" class="loginForm p-1">

                        <div class="form-group mt-2 mb-2">
                            <label for="email" class="sr-only mb-2" style="font-weight: bold;">Email</label>
                            <input type="email" name="email" id="email" formControlName="email" class="form-control" #emailInput
                                placeholder="" [class.is-invalid]="hasError || (loginForm.get('email')?.errors && 
                                loginForm.get('email')?.dirty)" (input)="checkEmail()">
                            <div *ngIf="loginForm.get('email')?.errors && 
                                loginForm.get('email')?.dirty" style="font-size: small; color: red;">
                                Formato della email non corretto
                            </div>    
                        </div>
                        <div class="form-group mt-2 mb-2">
                            <label for="password" class="sr-only mb-2" style="font-weight: bold;">Password</label>
                            <div class="input-group">
                                <input [type]="pswVisibility" name="password" id="password" formControlName="password"
                                    class="form-control" placeholder="" [class.is-invalid]="hasError || (loginForm.get('password')?.errors && 
                                    loginForm.get('password')?.dirty)">
                                <button class="btn btn-outline-dark" (click)="seePassword()">
                                    <i class="bi" [ngClass]="{'bi-eye': !toggled, 'bi-eye-slash': toggled}"
                                        id="togglePassword" style="z-index: 1000;"></i>
                                </button>
                            </div>
                            <div *ngIf="loginForm.get('password')?.errors && 
                                loginForm.get('password')?.dirty" style="font-size: small; color: red;">
                                La password non può essere vuota
                            </div> 
                        </div>

                        <!-- <mat-form-field class="example-full-width">
                                <mat-label>Email</mat-label>
                                <input type="email" matInput [formControl]="emailFormControl"
                                    placeholder="Ex. pat@example.com" #email>
                                @if (emailFormControl.hasError('email') && !emailFormControl.hasError('required')) {
                                <mat-error>Inserire un indirizzo email valido</mat-error>
                                }
                                @if (emailFormControl.hasError('required')) {
                                <mat-error>La email è <strong>richiesta</strong></mat-error>
                                }
                            </mat-form-field>

                            <mat-form-field class="example-full-width p-1">
                                <mat-label>Password</mat-label>
                                <input matInput type="Password" #password>
                                @if (emailFormControl.hasError('required')) {
                                <mat-error>La password è <strong>richiesta</strong></mat-error>
                                }
                            </mat-form-field> -->
                    </form>
                </div>
                <div class="text-end mb-2">
                    <a href="/passwordDimenticata">
                        <span style="font-size: small; font-style: italic;">Password Dimenticata?</span>
                    </a>
                </div>

                <!-- <div class="text-end">
                    <a href="/richiediAccesso">
                        <span style="font-size: small; font-style: italic;">Richiedi Accesso</span>
                    </a>
                </div> -->

                <div class="card-footer text-center pt-2 pb-2" style="background-color: white;">
                    <div *ngIf="hasError" style="color: red; font-size: small;">*Email e/o Password errate</div>
                    <button class="btn btn-outline-dark mt-2" (click)="checkAccess()">Accedi</button>
                </div>
            </div>
        </div>
    </div>
</div>