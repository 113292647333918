import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Article } from '../../../../interfaces/article';
import { CommonModule } from '@angular/common';
import { InViewportDirective } from '../../../../directives/in-viewport.directive';
import { Connect } from '../../../../classes/connect';
import { ArticleArtisticGeneral } from '../../../../interfaces/article-artistic';
import { PdfThumbnailService } from '../../../../services/pdf-thumbnail.service';

@Component({
  selector: 'app-card-archive',
  standalone: true,
  imports: [
    CommonModule,
    InViewportDirective
  ],
  templateUrl: './card-archive.component.html',
  styleUrl: './card-archive.component.scss'
})
export class CardArchiveComponent {

  thumbnailsA: { [key: string]: string } = {}; // Memorizza le thumbnail dei PDF Artistico
  thumbnailsH: { [key: string]: string } = {}; // Memorizza le thumbnail dei PDF Storico

  urlServerLaraApiMultimedia = Connect.urlServerLaraApiMultimedia;

  @Input() articlesH: Article[] = [];
  @Input() articlesA: ArticleArtisticGeneral[] = [];
  @Input() type: number = 0;

  @Output() navigate = new EventEmitter<number>()

  constructor(private pdfThumbnailService: PdfThumbnailService) {}

  ngOnInit(): void {
    this.generatePdfThumbnails();
  }

  async generatePdfThumbnails(): Promise<void> {
    for (const file of this.articlesA) {
      if (file.src && file.ext === 'pdf') {
        const thumbnail = await this.pdfThumbnailService.generateThumbnail(this.urlServerLaraApiMultimedia+file.src);
        this.thumbnailsA[file.src!] = thumbnail;
      }
    }

    for (const file of this.articlesH) {
      if (file.src && file.ext === 'pdf') {
        const thumbnail = await this.pdfThumbnailService.generateThumbnail(this.urlServerLaraApiMultimedia+file.src);
        this.thumbnailsH[file.src!] = thumbnail;
      }
    }
  }

  cardClick(id: number) {
    this.navigate.emit(id);
  }

}
