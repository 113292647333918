<div class="container-fluid p-4 p-lg-5" style="min-height: 84%; min-width: 350px; max-width: 1400px;">
    <div class="row justify-content-center align-items-center p-1 p-lg-2">
        <div class="col-12" style="position: relative;">
            <img src="assets/img/contactBar.png" class="d-none d-lg-block img-flex mx-auto"
                style="width: 90%; height: auto;">
            <span class="d-none d-lg-block" style="font-weight: bold; color: white; font-size: x-large;
                    position: absolute; top: 63%; left: 41%;">
                GESTIONE SCHEDA
            </span>
        </div>
        <div class="col-12 col-md-8 text-center">
            <div class="d-lg-none" style="background-color: #1E73BE; border-radius: 15px;">
                <span style="font-weight: bold; font-size: x-large; color: white;">
                    GESTIONE SCHEDA
                </span>
            </div>
        </div>
        <div class="col-12 text-center">
            <span style="font-size: small;">Campi obbligatori <sup>*</sup></span>
        </div>
    </div>
    <app-general-info [listType]="listType" (buttonClicked)="onButtonClicked($event)"></app-general-info>
</div>
