<div class="container-fluid sticky-filters mt-4" style="width: 100%;">
  <div class="row justify-content-center align-items-center">
    <form [formGroup]="filterForm">
      <div class="col-12 p-0 mt-1 text-end">
        <button (click)="resetFilters()" type="button" class="btn btn-sm btn-outline-dark" 
          style="font-size: small;">
          <i class="bi bi-arrow-clockwise"></i>
            Azzera Filtri
        </button>
      </div>
      <div class="col-12 mb-4 mt-0 nav-item listElement" style="border-bottom: 2px solid lightgray;">
        <h5 style="font-weight: bold; padding: 3px">RICERCA TESTUALE</h5>
        <div class="input-group mb-4" style="height: 50px;">
          <input type="text" class="form-control" placeholder="Cosa stai cercando?" (keydown.enter)="searchArticles(0)"
            id="title" formControlName="title">
          <button class="btn btn-outline-dark searchButton" (click)="searchArticles(0)" type="button"
            id="button-addon2">Cerca
          </button>
        </div>
      </div>
      <div class="col-12 nav-item listElement">
        <h5 style="font-weight: bold;">CATEGORIE</h5>
        <span *ngFor="let category of categoryOptions;">
          <input type="checkbox" class="btn-check catSelector" [id]="'cat_'+category.id" autocomplete="off"
            (click)="toggleCategory(category)" [checked]="isSelected(category)">
          <label class="btn btn-outline-dark btn-sm mx-2 mt-1" [for]="'cat_'+category.id">{{category.title}}</label>
        </span>
      </div>
      <div class="col-12 nav-item listElement">
        <h5 style="font-weight: bold;">CITTA'</h5>
        <div class="input-group mb-3" style="height: 50px;">
          <!-- <input type="text" placeholder="Pick one" matInput [formControl]="formControl"
                      [matAutocomplete]="auto" class="form-control" placeholder="Comune">
              </div>
              <mat-autocomplete #auto="matAutocomplete">
                  @for (option of filteredOptions | async; track option) {
                  <mat-option [value]="option">{{option}}</mat-option>
                  }
              </mat-autocomplete> -->
          <input type="text" matInput [matAutocomplete]="city" class="form-control" placeholder="Comune" id="city"
            formControlName="city"
            [class.is-invalid]="filterForm.get('city')?.invalid &&
                      (filterForm.get('city')?.dirty || filterForm.get('city')?.touched) && filterForm.get('city')?.value !== null">
        </div>
      </div>
      <mat-autocomplete #city="matAutocomplete" [displayWith]="displayCityName">
        <mat-option *ngFor="let option of filteredCity$ | async" [value]="option">
          <div>
            {{option.name}}
          </div>
        </mat-option>
      </mat-autocomplete>
      <div class="col-12 nav-item listElement">
        <h5 style="font-weight: bold;">LUOGO D'INTERESSE</h5>
        <div class="input-group mb-3" style="height: 50px;">
          <input type="text" class="form-control" placeholder="Luogo" formControlName="pointofinterest">
        </div>
      </div>

      <div class="col-12 nav-item listElement">
        <h5 style="font-weight: bold;">DATA</h5>
        <div class="btn-group btn-group-toggle mb-2" role="group" aria-label="selectFormat">
          <input type="radio" class="btn-check" name="yearFormat" id="date" autocomplete="off"
            (click)="changeFormat('date')" [checked]="yearFormat === 'date'">
          <label class="btn btn-outline-dark" for="date">Data</label>

          <input type="radio" class="btn-check" name="yearFormat" id="year" autocomplete="off"
            (click)="changeFormat('year')" [checked]="yearFormat === 'year'">
          <label class="btn btn-outline-dark" for="year">Anno</label>

          <input type="radio" class="btn-check" name="yearFormat" id="period" autocomplete="off"
            (click)="changeFormat('period')" [checked]="yearFormat === 'period'">
          <label class="btn btn-outline-dark" for="period">Periodo</label>
        </div>

        <div *ngIf="yearFormat == 'date'">
          <div class="input-group mt-1 mb-3">
            <input type="date" class="form-control" placeholder="GG/MM/AAAA" formControlName="date_article"
              style="height: 50px;">
          </div>
        </div>

        <div *ngIf="yearFormat == 'year'">
          <div class="input-group mt-1 mb-3">
            <input type="text" class="form-control" placeholder="Anno singolo" id="year_article"
              formControlName="year_article" style="height: 50px;"
              [class.is-invalid]="filterForm.get('year_article')?.invalid && (filterForm.get('year_article')?.dirty || filterForm.get('year_article')?.touched)">
          </div>
        </div>

        <div *ngIf="yearFormat == 'period'">
          <div class="input-group mt-1 mb-3">
            <input type="text" class="form-control" placeholder="Dal" id="yearFrom_article"
              formControlName="yearFrom_article" style="height: 50px;" (ngModelChange)="validPeriod()"
              [class.is-invalid]="filterForm.get('yearFrom_article')?.invalid && (filterForm.get('yearFrom_article')?.dirty || filterForm.get('yearFrom_article')?.touched)">
            <input type="text" class="form-control" placeholder="Al" id="yearTo_article"
              formControlName="yearTo_article" style="height: 50px;" (ngModelChange)="validPeriod()"
              [class.is-invalid]="filterForm.get('yearTo_article')?.invalid && (filterForm.get('yearTo_article')?.dirty || filterForm.get('yearTo_article')?.touched)">
          </div>
        </div>
      </div>

      <div class="col-12" style="margin-top: 15px; margin-bottom: 15px;">
        <button (click)="searchArticles(1)" type="button" class="btn btn-outline-dark" style="width: 100%;"
          [class.disabled]="filterForm.invalid">
          Cerca
        </button>
      </div>
    </form>
  </div>
</div>
