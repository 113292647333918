import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { debounceTime, filter, map, Observable, startWith, switchMap } from 'rxjs';
import { ArticlesService } from '../../../services/articles.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OptionsService } from '../../../services/options.service';
import { UploadImageService } from '../../../services/upload-image.service';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Connect } from '../../../classes/connect';
import { ConnectServerService } from '../../../services/connect-server.service';
import { ApiResponse } from '../../../interfaces/api-response';
import { MatSelectModule } from '@angular/material/select';
import { Article, Category, City, SubCategory } from '../../../interfaces/article';
import { SearchAutocompleteService } from '../../../services/search-autocomplete.service';
import { cityValidator } from '../../../validators/city-validator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateFnsAdapter, MAT_DATE_FNS_FORMATS } from '@angular/material-date-fns-adapter';
import { it } from 'date-fns/locale';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

@Component({
  selector: 'app-article-historical-insert',
  standalone: true,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDatepickerModule
  ],
  templateUrl: './article-historical-insert.component.html',
  styleUrl: './article-historical-insert.component.scss',
  providers:
    [
      { provide: MAT_DATE_LOCALE, useValue: it },
      { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
      { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
      { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }
    ],
})
export class ArticleHistoricalInsertComponent {

  isCorrectYear = true;
  year = new Date().getFullYear();
  article!: Article;
  errorMessage: string = '';
  cover: string | undefined = '';
  coverIndex: number | null = null;
  images: string[] = [];
  place: string = '';
  categoryOptions: Category[] = [];
  subcategoryOptions: SubCategory[] = [];
  filteredCity$!: Observable<City[]>;
  cityOptions: any[] = [];
  yearOptions: any[] = [];
  filteredYearOptions: Observable<string[]> = new Observable;
  filteredCategoryOptions: Observable<string[]> = new Observable;
  id: number | null = null;
  modify: boolean = false;
  yearFormat: string | null = null;

  articleForm = new FormGroup({
    category: new FormControl<null | Category>(null, Validators.required),
    subCategory: new FormControl<null | SubCategory>(null, Validators.required),
    title: new FormControl('', Validators.required),
    description: new FormControl<string | null>(null),
    dateType: new FormControl<number | null>(null),
    city: new FormControl<City | string>({ value: '', disabled: true }, [cityValidator()]),
    pointOfInterest: new FormControl<string | null>(null),
    location: new FormControl<string | null>(null),
    year: new FormControl<string | null>(null, Validators.pattern('^[0-9]{1,4}$')),
    date: new FormControl<Date | null>(null),
    yearFrom: new FormControl<string | null>(null, Validators.pattern('^[0-9]{1,4}$')),
    yearTo: new FormControl<string | null>(null, Validators.pattern('^[0-9]{1,4}$')),
    public: new FormControl<number>(0),
    room: new FormControl<string | null>(null, Validators.required),
    floor: new FormControl<string | null>(null, Validators.required),
    rack: new FormControl<string | null>(null, Validators.required),
    shelf: new FormControl<string | null>(null, Validators.required),
    case: new FormControl<string | null>(null, Validators.required),
    // code: new FormControl<string | null>(null, Validators.required),
    public_year: new FormControl<number | null>({ value: null, disabled: true }),
  })


  constructor(private articlesService: ArticlesService, private optionsService: OptionsService,
    private connectServerService: ConnectServerService, private router: Router,
    private searchAutocompleteService: SearchAutocompleteService) { }

  ngOnInit(): void {

    this.getCategories();
    this.formLogic();
    this.searchCity();
    this.yearOptions = this.optionsService.getYearOptions();
    // let yearFrom = this.articleForm.get('yearFrom');
    // let yearTo = this.articleForm.get('yearTo');
    // if (yearFrom) {
    //   this.filteredYearOptions = yearFrom.valueChanges.pipe(
    //     startWith(''),
    //     map(value => this.filterYear(value || '')),
    //   );
    // }
    // if (yearTo) {
    //   this.filteredYearOptions = yearTo.valueChanges.pipe(
    //     startWith(''),
    //     map(value => this.filterYear(value || '')),
    //   );
    // }

    const allControls = ['subCategory', 'title', 'description', 'city', 'pointOfInterest', 'location', 'date', 'yearFrom', 'yearTo', 'public', 'year',
      'room', 'floor', 'rack', 'shelf', 'case'
    ];

    if (this.id == null) {
      allControls.forEach(control => {
        const controlInstance = this.articleForm.get(control);
        controlInstance?.disable();
      })
    }

    this.articleForm.get('category')?.valueChanges.subscribe(value => {
      allControls.forEach(control => {
        const controlInstance = this.articleForm.get(control);
        if (value) {
          controlInstance?.enable();
        } else {
          controlInstance?.disable();
        }
      });
    });

    this.articleForm.get('public')?.valueChanges.subscribe(value => {
      this.articleForm.get('public')?.setValue(value ? 1 : 0, { emitEvent: false });
    });
  }

  enableDisablePublication() {
    if (this.articleForm.get('public')!.value == 0) {
      this.articleForm.get('public_year')?.disable();
      this.articleForm.get('public_year')?.setValue(null);
      this.articleForm.get('public_year')?.clearValidators()
    }
    else {
      this.articleForm.get('public_year')?.enable();
      this.articleForm.get('public_year')?.setValue(this.year);
      this.articleForm.get('public_year')?.addValidators(Validators.required);
    }
  }

  isCorrectPublicYear() {
    if (/^[0-9]+$/.test(this.articleForm.get('public_year')!.value!.toString())) {
      if (this.articleForm.get('public_year')?.value! < this.year || this.articleForm.get('public_year')?.value! > 9999) {
        this.articleForm.get('public_year')?.setErrors({invalidYear: true});
      }
      else this.articleForm.get('public_year')?.setErrors(null);
    }
    else this.articleForm.get('public_year')?.setErrors({invalidYear: true});
  }

  isCorrectPeriodLogic() {
    if (parseInt(this.articleForm.get('yearFrom')?.value!) > 99 && parseInt(this.articleForm.get('yearTo')?.value!) > 99
        && parseInt(this.articleForm.get('yearFrom')?.value!) < 9999 && parseInt(this.articleForm.get('yearTo')?.value!) < 9999 &&
        (/^[0-9]+$/.test(this.articleForm.get('yearFrom')!.value!.toString()) && /^[0-9]+$/.test(this.articleForm.get('yearTo')!.value!.toString()))) {
      if ((parseInt(this.articleForm.get('yearFrom')?.value!) - parseInt(this.articleForm.get('yearTo')?.value!)) >= 0) {
        this.articleForm.get('yearTo')?.setErrors({ invalidPeriod: true });
        this.articleForm.get('yearFrom')?.setErrors({ invalidPeriod: true });
      }
      else {
        this.articleForm.get('yearTo')?.setErrors(null);
        this.articleForm.get('yearFrom')?.setErrors(null);
      }
    }
    else {
      this.articleForm.get('yearTo')?.setErrors({ invalidPeriod: true });
      this.articleForm.get('yearFrom')?.setErrors({ invalidPeriod: true });
    }
  }

  isCorrectYearLogic() {
    if (parseInt(this.articleForm.get('year')?.value!) > 99 && parseInt(this.articleForm.get('year')?.value!) < 9999  && (/^[0-9]+$/.test(this.articleForm.get('year')!.value!.toString()))) {
        this.articleForm.get('year')?.setErrors(null);
      }
    else this.articleForm.get('year')?.setErrors({ invalidYear: true });
  }

  // private filterYear(value: string): string[] {
  //   this.place = value.toLowerCase();
  //   return this.yearOptions.filter(option => option.toLowerCase().includes(this.place));
  // }

  // setVisibility() {
  //   const isChecked = this.articleForm.get('public')?.value;
  //   const visibilityValue = isChecked ? 1 : 0;
  //   this.articleForm.get('public')?.setValue(visibilityValue);
  //   console.log(`Visibility changed to: ${visibilityValue}`);
  // }

  insertArticle() {
    // let dateValue: Date | null = null;
    // let str_date = '';
    // const dateControl = this.articleForm.get('date');
    // if (dateControl) {
    //   dateValue = dateControl.value;
    //   if (dateValue) {
    //     str_date = this.optionsService.fromDateToString(dateValue);
    //   }
    // }
    this.article = {
      category: this.articleForm.get('category')?.value!,
      subcategory: this.articleForm.get('subCategory')?.value!,
      title: this.articleForm.get('title')?.value!,
      description: this.articleForm.get('description')?.value!,
      date_type: this.articleForm.get('dateType')?.value!,
      city: this.articleForm.get('city')?.value!,
      pointofinterest: this.articleForm.get('pointOfInterest')?.value!,
      storage_place: this.articleForm.get('location')?.value!,
      date_article: this.articleForm.get('date')?.value!,
      year_article: this.articleForm.get('year')?.value!,
      yearfrom_article: this.articleForm.get('yearFrom')?.value!,
      yearto_article: this.articleForm.get('yearTo')?.value!,
      public: this.articleForm.get('public')?.value!,
      room: this.articleForm.get('room')?.value!,
      floor: this.articleForm.get('floor')?.value!,
      rack: this.articleForm.get('rack')?.value!,
      shelf: this.articleForm.get('shelf')?.value!,
      case: this.articleForm.get('case')?.value!,
      public_year: this.articleForm.get('public_year')?.value!,
      // code: this.articleForm.get('code')?.value!,
      id: 0,
    }

    // console.log("date: ", this.articleForm.get('date')?.value!)

    this.sendArticle();
  }

  changeFormat(type: string) {
    if (type === "date") {
      this.resetYearValue(type);
      if (this.yearFormat === "date") {
        this.articleForm.get('dateType')!.setValue(null);
        this.yearFormat = null;
      } else {
        this.yearFormat = type;
        this.articleForm.get('dateType')!.setValue(0);
      }
    } else if (type === "year") {
      this.resetYearValue(type);
      if (this.yearFormat === "year") {
        this.articleForm.get('dateType')!.setValue(null);
        this.articleForm.get('year')!.setValue(null);
        this.yearFormat = null;
      } else {
        this.yearFormat = type;
        this.articleForm.get('dateType')!.setValue(1);
      }
    } else if (type === "period") {
      this.resetYearValue(type);
      if (this.yearFormat === "period") {
        this.articleForm.get('dateType')!.setValue(null);
        this.yearFormat = null;
      } else {
        this.yearFormat = type;
        this.articleForm.get('dateType')!.setValue(2);
      }
    }
  }

  private formLogic() {
    this.articleForm.get('category')?.valueChanges.subscribe(
      (val: Category | null) => {
        if (val?.id) {
          this.getSubcategories(val.id);
        }
      }
    )
  }

  private resetYearValue(type: string) {
    if (type === "date") {
      this.articleForm.get('yearFrom')!.setValue(null);
      this.articleForm.get('yearTo')!.setValue(null);
      this.articleForm.get('year')!.setValue(null);
    } else if (type === "year") {
      this.articleForm.get('yearFrom')!.setValue(null);
      this.articleForm.get('yearTo')!.setValue(null);
      this.articleForm.get('date')!.setValue(null);
    } else if (type === "period") {
      this.articleForm.get('year')!.setValue(null);
      this.articleForm.get('date')!.setValue(null);
    }
  }

  private sendArticle() {
    // console.log("info: ", this.article);
    this.connectServerService.postRequest<ApiResponse<{ id: number }>>('insertArticleHistorical',
      this.article).
      subscribe(
        (val: ApiResponse<{ id: number }>) => {
          // console.log("insert: ", val.data)
          if (val.data && val.data.id) {
            const id = val.data.id;
            this.router.navigate(['modificaSchedaStorica', id]);
          }
          //
        },
      );
  }

  private getCategories() {
    this.connectServerService.getRequest('categories', {})
      .subscribe(
        (val: ApiResponse<{ categories: Category[] }>) => {
          this.categoryOptions = val.data.categories;
        }
      )
  }

  private getSubcategories(idcategory: number): void {
    this.connectServerService.getRequest<ApiResponse<{ subCategories: SubCategory[] }>>
      ('subCategories', { id: idcategory })
      .subscribe(
        (val: ApiResponse<{ subCategories: SubCategory[] }>) => {
          if (val.data && val.data.subCategories) {
            this.subcategoryOptions = val.data.subCategories;
          }
        }
      )
  }

  private searchCity() {
    // ricerca
    const campo_city = this.articleForm.get('city');
    if (campo_city) {
      this.filteredCity$ = campo_city.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value?.name || ''),
          filter(value => value.length > 0),
          debounceTime(600),
          switchMap((value: string) =>
            value ? this.searchAutocompleteService.getCity(value) : [])
        );
    }
  }

  displayCityName(city?: City): string {
    return city ? city.name : '';
  }
}
