import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { authGuard } from './guards/auth.guard';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { SignupComponent } from './pages/signup/signup.component';
import { ImportFileComponent } from './pages/settings/import-file/import-file.component';
import { ForgotPasswordComponent } from './pages/password-management/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/password-management/reset-password/reset-password.component';
import { ArchiveHistoricalComponent } from './pages/archive/archive-historical/archive-historical.component';
import { ArchiveArtisticComponent } from './pages/archive/archive-artistic/archive-artistic.component';
import { ArticleHistoricalComponent } from './pages/article/article-historical/article-historical.component';
import { ArticleArtisticComponent } from './pages/article/article-artistic/article-artistic.component';
import { ArticleHistoricalInsertComponent } from './pages/article/article-historical-insert/article-historical-insert.component';
import { ArticleArtisticInsertComponent } from './pages/article/article-artistic-insert/article-artistic-insert.component';
import { ArticleHistoricalUpdateComponent } from './pages/article/article-historical-update/article-historical-update.component';
import { ArticleArtisticUpdateComponent } from './pages/article/article-artistic-update/article-artistic-update.component';
import { InventoryHistoricalComponent } from './pages/inventory/inventory-historical/inventory-historical.component';
import { InventoryArtisticComponent } from './pages/inventory/inventory-artistic/inventory-artistic.component';
import { ProfileSettingsComponent } from './pages/profile-settings/profile-settings.component';
import { ImportArtisticDefinitionComponent } from './pages/settings/import-artistic-definition/import-artistic-definition.component';
import { PagesComponent } from './pages/authorizations/pages/pages.component';
import { PermissionsComponent } from './pages/authorizations/permissions/permissions.component';
import { UsersComponent } from './pages/authorizations/users/users.component';
import { PdfDisplayComponent } from './pages/article/components/pdf-display/pdf-display.component';
import { ImportArticlesFromFolderComponent } from './pages/import-articles-from-folder/import-articles-from-folder.component';
import { ImportArticlesFromPdfComponent } from './pages/import-articles-from-pdf/import-articles-from-pdf.component';

export const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [authGuard] },

  // storico
  { path: 'archivioStorico', component: ArchiveHistoricalComponent, canActivate: [authGuard] },
  { path: 'archivioStorico/scheda/:id', component: ArticleHistoricalComponent, canActivate: [authGuard] },
  { path: 'nuovaSchedaStorica', component: ArticleHistoricalInsertComponent, canActivate: [authGuard] },
  { path: 'modificaSchedaStorica/:id', component: ArticleHistoricalUpdateComponent, canActivate: [authGuard] },
  { path: 'inventarioStorico', component: InventoryHistoricalComponent, canActivate: [authGuard] },

  { path: 'importazioneArticoliDaPdf', component: ImportArticlesFromPdfComponent, canActivate: [authGuard] },
  { path: 'importazioneArticoliDaCartella', component: ImportArticlesFromFolderComponent, canActivate: [authGuard] },

  // artistico
  { path: 'archivioArtistico', component: ArchiveArtisticComponent, canActivate: [authGuard] },
  { path: 'archivioArtistico/scheda/:id', component: ArticleArtisticComponent, canActivate: [authGuard] },
  { path: 'nuovaSchedaArtistica', component: ArticleArtisticInsertComponent, canActivate: [authGuard] },
  //{ path: 'modificaSchedaArtistica/:id', component: ArticleArtisticUpdateComponent, canActivate: [authGuard] },
  { path: 'inventarioArtistico', component: InventoryArtisticComponent, canActivate: [authGuard] },
  { path: 'nuovaSchedaArtistica', component: ArticleArtisticInsertComponent, canActivate: [authGuard] },
  { path: 'modificaSchedaArtistica/:id', component: ArticleArtisticUpdateComponent, canActivate: [authGuard] },

  // profilo ed accesso
  { path: 'settings', component: ProfileSettingsComponent, canActivate: [authGuard] },
  { path: 'autorizzazioni/utenti', component: UsersComponent, canActivate: [authGuard] },

  // { path: 'archivio/scheda/imageviewer/:id', component: ImageViewerComponent, canActivate: [authGuard] },
  { path: 'passwordDimenticata', component: ForgotPasswordComponent, canActivate: [authGuard] },
  { path: 'chisiamo', component: AboutUsComponent, canActivate: [authGuard] },
  { path: 'contatti', component: ContactsComponent, canActivate: [authGuard] },

  { path: 'reimpostaPassword/:token', component: ResetPasswordComponent },
  { path: 'accessonegato', component: AccessDeniedComponent },
  { path: 'visualizzaPdf', component: PdfDisplayComponent, },

  // rotte nascoste, non si vedono nel menu
  { path: 'login', component: LoginComponent, canActivate: [authGuard] },
  { path: 'richiediAccesso', component: SignupComponent, canActivate: [authGuard] },
  { path: 'impostazioni/importazioneFiles', component: ImportFileComponent, canActivate: [authGuard] },
  { path: 'impostazioni/importazioneDefinizioni', component: ImportArtisticDefinitionComponent, canActivate: [authGuard] },
  { path: 'autorizzazioni/pagine', component: PagesComponent, canActivate: [authGuard] },
  { path: 'autorizzazioni/permessi', component: PermissionsComponent, canActivate: [authGuard] },

  { path: '**', redirectTo: 'home' },

  // { path: 'modificascheda', component: ArticleUpdateComponent, canActivate: [authGuard] },
  // { path: 'cimitero', component: ContractsListComponent, canActivate: [authGuard] },
  // { path: 'cimitero/contratto/:id', component: ContractComponent, }
];
