<div class="container-fluid sticky-header">
  <div class="row justify-content-end align-items-center mt-2 borderBottom">
    <div class="col-2">

    </div>
    <div class="col-8 text-center">
      <span style="font-size: x-large; font-weight: bold;">
        Modifica Visualizzazione Schede Homepage
      </span>
    </div>
    <div class="col-2 text-end mb-2">
      <!-- <button class="btn btn-outline-dark" (click)="closeDialog(0)">
        <i class="bi bi-x"></i>
      </button> -->
    </div>
  </div>
</div>

<div class="container-fluid">
  <!-- CARD  -->
  <div class="row justify-content-center align-items-center p-1 p-md-2 p-lg-3 mt-0 mt-xxl-4" style="cursor: default;">
    <div *ngFor="let card of cards; let i=index;" class="col-12 col-md-6 col-xxl-3 mt-1" style="position: relative">

      <app-card *ngIf="card.id != 0; else emptyBlock" [obj_card]="card"
      [counter]="i+1" [thumbnails]="thumbnails"></app-card>
      <ng-template #emptyBlock>
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-12 text-center">
              <div class="row px-5 align-items-center justify-content-center"
                style="height: 240px; border: 1px solid black; border-radius: 15px;">
                SPAZIO LIBERO PER NUOVA SCHEDA
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <!-- SELEZIONE CARD -->
  <form [formGroup]="cardsForm">
    <div class="row justify-content-center align-items-center p-1 p-md-2 p-lg-3 mt-0 mt-xxl-4">
      <div class="col-12 col-md-6 col-xxl-3">
        <label for="card1">Prima Card</label>
        <input type="text" matInput [matAutocomplete]="card1" class="form-control" placeholder="Titolo card" id="card1"
          style="height: 50px;" formControlName="card1"
          [class.is-invalid]="
          cardsForm.get('card1')?.hasError('invalidAutocomplete') &&
          cardsForm.get('card1')?.value
        " />
        <mat-autocomplete #card1="matAutocomplete" [displayWith]="displayArticleName">
          <mat-option *ngFor="let option of filteredArticles$[0] | async" [value]="option">
            <div>
              {{option.title}}
            </div>
          </mat-option>
        </mat-autocomplete>
        <div *ngIf="cardsForm.get('card1')?.hasError('invalidAutocomplete') &&
      cardsForm.get('card1')?.value" class="invalid-feedback">
          Valore non valido, selezionare dalla lista.
        </div>
      </div>

      <div class="col-12 col-md-6 col-xxl-3">
        <label for="card2">Seconda Card</label>
        <input type="text" matInput [matAutocomplete]="card2" class="form-control" placeholder="Titolo card" id="card2"
          formControlName="card2" style="height: 50px;"
          [class.is-invalid]="cardsForm.get('card2')?.hasError('invalidAutocomplete')">
        <mat-autocomplete #card2="matAutocomplete" [displayWith]="displayArticleName">
          <mat-option *ngFor="let option of filteredArticles$[1] | async" [value]="option">
            <div>
              {{option.title}}
            </div>
          </mat-option>
        </mat-autocomplete>
        <div *ngIf="cardsForm.get('card2')?.hasError('invalidAutocomplete')" class="invalid-feedback">
          Valore non valido, selezionare dalla lista.
        </div>
      </div>

      <div class="col-12 col-md-6 col-xxl-3">
        <label for="card3">Terza Card</label>
          <input type="text" matInput [matAutocomplete]="card3" class="form-control" placeholder="Titolo card"
            id="card3" formControlName="card3" style="height: 50px;"
            [class.is-invalid]="cardsForm.get('card3')?.hasError('invalidAutocomplete')">
        <mat-autocomplete #card3="matAutocomplete" [displayWith]="displayArticleName">
          <mat-option *ngFor="let option of filteredArticles$[2] | async" [value]="option">
            <div>
              {{option.title}}
            </div>
          </mat-option>
        </mat-autocomplete>
        <div *ngIf="cardsForm.get('card3')?.hasError('invalidAutocomplete')" class="invalid-feedback">
          Valore non valido, selezionare dalla lista.
        </div>
      </div>

      <div class="col-12 col-md-6 col-xxl-3">
        <label for="card4">Quarta Card</label>
        <input type="text" matInput [matAutocomplete]="card4" class="form-control" placeholder="Titolo card" id="card4"
          formControlName="card4" style="height: 50px;"
          [class.is-invalid]="cardsForm.get('card4')?.hasError('invalidAutocomplete')">
        <mat-autocomplete #card4="matAutocomplete" [displayWith]="displayArticleName">
          <mat-option *ngFor="let option of filteredArticles$[3] | async" [value]="option">
            <div>
              {{option.title}}
            </div>
          </mat-option>
        </mat-autocomplete>
        <div *ngIf="cardsForm.get('card4')?.hasError('invalidAutocomplete')" class="invalid-feedback">
          Valore non valido, selezionare dalla lista.
        </div>
      </div>
    </div>
    <div class="text-end mb-3">
      <button type="button" class="btn btn-outline-primary me-2" (click)="saveCards()">Aggiorna</button>
      <button type="button" class="btn btn-outline-dark" (click)="closeDialog(1)">Chiudi</button>
    </div>

  </form>
</div>
