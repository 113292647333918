import { Component, inject } from '@angular/core';
import { GeneralInfoComponent } from "../article-artistic-cards/general-info/general-info.component";
import { ObjectComponent } from "../article-artistic-cards/object/object.component";
import { LocationDataComponent } from "../article-artistic-cards/location-data/location-data.component";
import { ChronologyComponent } from "../article-artistic-cards/chronology/chronology.component";
import { CulturalDefinitionComponent } from "../article-artistic-cards/cultural-definition/cultural-definition.component";
import { TechnicalDataComponent } from "../article-artistic-cards/technical-data/technical-data.component";
import { ConservationComponent } from "../article-artistic-cards/conservation/conservation.component";
import { ActivatedRoute } from '@angular/router';
import { ConnectServerService } from '../../../services/connect-server.service';
import { Connect } from '../../../classes/connect';
import { ApiResponse } from '../../../interfaces/api-response';
import { SelectOptions } from '../interfaces/select-options';
import { SelectComplete } from '../interfaces/select-complete';
import { AnalyticalDataComponent } from "../article-artistic-cards/analytical-data/analytical-data.component";
import { SelectChronology } from '../interfaces/select-chronology';
import { LegalConditionComponent } from "../article-artistic-cards/legal-condition/legal-condition.component";
import { SelectCulturalDefinition } from '../interfaces/select-cultural-definition';
import { SelectTechnicalData } from '../interfaces/select-technical-data';
import { SourcesComponent } from "../article-artistic-cards/sources/sources.component";

@Component({
  selector: 'app-article-artistic-update',
  standalone: true,
  imports: [
    GeneralInfoComponent,
    ObjectComponent,
    LocationDataComponent,
    ChronologyComponent,
    CulturalDefinitionComponent,
    TechnicalDataComponent,
    ConservationComponent,
    AnalyticalDataComponent,
    LegalConditionComponent,
    SourcesComponent
  ],
  templateUrl: './article-artistic-update.component.html',
  styleUrl: './article-artistic-update.component.scss'
})
export class ArticleArtisticUpdateComponent {

  idarticle = 0;
  readonly route = inject(ActivatedRoute);

  listComplete: SelectComplete | null = null;
  listChronology: SelectChronology | null = null;
  listCulturalDefinition: SelectCulturalDefinition | null = null;
  listTechnicalData: SelectTechnicalData | null = null;

  constructor(private connectServerService: ConnectServerService) {

  }

  ngOnInit(): void {
    const parameters = this.route.snapshot.paramMap.get('id');
    if (parameters) {
      this.route.params.subscribe(
        (params) => {
          //console.error('params: ', params['name'])
          this.idarticle = params['id'];
          this.getSelectOptions();
        }
      );
    }
  }

  getSelectOptions() {
    this.connectServerService.getRequest('listAADefinition', {})
      .subscribe(
        (val: ApiResponse<{ listComplete: SelectComplete }>) => {
          this.listComplete = val.data.listComplete;
          this.listChronology = {
            listChronCentury: this.listComplete.listChronCentury,
            listChronValV: this.listComplete.listChronValV,
            listChronValF: this.listComplete.listChronValL,
            listChronReason: this.listComplete.listChronReason
          }
          this.listCulturalDefinition = {
            listAuthorAuth: this.listComplete.listAuthorAuth,
            listAuthorInt: this.listComplete.listAuthorInt,
            listAuthorReason: this.listComplete.listAuthorReason,
            listScopeDen: this.listComplete.listScopeDen,
            listScopeRef: this.listComplete.listScopeRef,
            listScopeReason: this.listComplete.listScopeReason,
          };
          this.listTechnicalData = {
            materialOptions: this.listComplete.materialOptions,
            unitOptions: this.listComplete.unitOptions,
            missingOptions: this.listComplete.missingOptions,
            validityOptions: this.listComplete.validityOptions
          }
        }
      )
  }

  onButtonClicked() {
    console.log('Il bottone nel componente figlio è stato cliccato!');
  }

}
