import { Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ArticlesService } from '../../../services/articles.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Connect } from '../../../classes/connect';
import { ApiResponse } from '../../../interfaces/api-response';
import { Article } from '../../../interfaces/article';
import { Filters } from '../../../interfaces/filters';
import { ArticleArtisticGeneral } from '../../../interfaces/article-artistic';
import { CardArchiveComponent } from "../components/card-archive/card-archive.component";

@Component({
  selector: 'app-archive-artistic',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CardArchiveComponent
],
  templateUrl: './archive-artistic.component.html',
  styleUrl: './archive-artistic.component.scss'
})
export class ArchiveArtisticComponent {
  urlServerLaraApiMultimedia = Connect.urlServerLaraApiMultimedia;

  totalResults: number = 0;
  totalPages: number = 1;
  total: number = 0;
  itemsPerPage: number = 50;
  currentPageIndex: number = 1;

  filters!: string | null;
  type_filter = 0 // Ricerca testuale 0, ricerca non testuale != 0

  articles: ArticleArtisticGeneral[] = [];

  formControl = new FormControl('');
  options: string[] = [];
  filteredOptions: Observable<string[]> = new Observable;

  filterForm = new FormGroup({
    title: new FormControl<string | null>(null, Validators.required)
  })

  constructor(public articlesService: ArticlesService, private router: Router, private route: ActivatedRoute) { }

  navigate(id: number) {
    this.router.navigate(['scheda', id], { relativeTo: this.route })
  }

  ngOnInit() {
    let searchText = null;
    this.route.queryParamMap.subscribe(params => {
      searchText = params.get('text');
      this.filterForm.get('title')?.setValue(searchText);
    });
    if (searchText && searchText != '') {
      this.searchArticles();
    }
    else {
      this.listaArticles();
    }

  }

  private listaArticles() {
    this.articlesService.getArticlesArtisticFromServer(this.itemsPerPage, this.currentPageIndex, this.filters!).
      subscribe((val: ApiResponse<{ listArticles: ArticleArtisticGeneral[], totalPages: number, totalResults: number }>) => {
        this.articles = val.data.listArticles;
        this.totalPages = val.data.totalPages;
        this.totalResults = val.data.totalResults;
        if(this.totalPages == 0) {
          this.totalPages += 1;
        }
      });
  }

  searchArticles() {
    this.type_filter = 0;
    this.filters = this.filterForm.get('title')?.value!;
    this.articlesService.getArticlesArtisticFromServer(this.itemsPerPage, 1, this.filters).
      subscribe((val: ApiResponse<{ listArticles: ArticleArtisticGeneral[], totalPages: number, totalResults: number }>) => {
        this.articles = val.data.listArticles;
        this.totalPages = val.data.totalPages;
        this.totalResults = val.data.totalResults;
        if(this.totalPages == 0) {
          this.totalPages += 1;
        }
      });

  }


  setCurrentPage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.articlesService.getArticlesFromServer(this.itemsPerPage, page, this.type_filter, this.filters).
        subscribe((val: ApiResponse<{ listArticles: Article[], totalPages: number }>) => {
          this.articles = val.data.listArticles;
          this.totalPages = this.totalPages;
          this.currentPageIndex = page;
        });
    }
  }

  previousPage() {
    if (this.currentPageIndex > 1) {
      this.articlesService.getArticlesFromServer(this.itemsPerPage, this.currentPageIndex - 1, this.type_filter, this.filters).
        subscribe((val: ApiResponse<{ listArticles: Article[], totalPages: number }>) => {
          this.articles = val.data.listArticles;
          this.totalPages = this.totalPages;
          this.currentPageIndex--;
        });
    }
  }

  nextPage() {
    if (this.currentPageIndex + 1 <= this.totalPages) {
      this.articlesService.getArticlesFromServer(this.itemsPerPage, this.currentPageIndex + 1, this.type_filter, this.filters).
        subscribe((val: ApiResponse<{ listArticles: Article[], totalPages: number }>) => {
          this.articles = val.data.listArticles;
          this.totalPages = this.totalPages;
          this.currentPageIndex++;
        });
    }
  }

  get pages(): Array<number> {
    // const pageCount = Math.ceil(this.articles.length / this.itemsPerPage);
    let totalPages = Array.from({ length: this.totalPages }, (_, index) => index + 1);
    if (this.totalPages > 4) {
      let reducedPages: Array<number> = [];
      if (this.currentPageIndex < 3) {
        return reducedPages = [1, 2, 3, 4];
      }
      else {
        if (this.totalPages - this.currentPageIndex < 3) {
          return reducedPages = [this.totalPages - 3, this.totalPages - 2, this.totalPages - 1, this.totalPages];
        }
        else {
          const current = this.currentPageIndex;
          return reducedPages = [current - 1, current, current + 1, current + 2];
        }
      }
    }
    else {
      if(totalPages.length == 0) {
        totalPages = [1];
      }
      return totalPages;
    }
  }

}
