import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ConnectServerService } from '../../../services/connect-server.service';
import { Connect } from '../../../classes/connect';
import { ApiResponse } from '../../../interfaces/api-response';
import { User, UserAuthorization } from '../../../interfaces/user';
import { PopupDialogService } from '../../../services/popup-dialog.service';

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule
  ],
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss'
})
export class UsersComponent {

  usersForm: FormGroup;

  constructor(private fb: FormBuilder, private connectServerService: ConnectServerService,
    private popupService: PopupDialogService
  ) {
    this.usersForm = this.fb.group({
      users: this.fb.array([])
    })
  }

  ngOnInit(): void {
    //TEMPORANEO IN ASSENZA DI CHIAMATA
   //  this.createNestedFields(this.userList);

    //DA ATTIVARE IN PRESENZA DI CHIAMATA
    this.getUserInfo();
  }

  createUserForm(content: UserAuthorization) {
    return this.fb.group({
      id: [content.id],
      nickname: [content.nickname],
      email: [content.email],
      authorization: [content.authorization]
    })
  }

  get users(): FormArray {
    return this.usersForm.get('users') as FormArray;
  }

  addUser(content: UserAuthorization) {
    this.users.push(this.createUserForm(content));
  }

  createNestedFields(userForm: UserAuthorization[]) {
    userForm.forEach(element => {
      this.addUser(element);
    })
  }

  private getUserInfo() {
    this.connectServerService.getRequest('listUsers', {  }, Connect.urlServerLaraApi)
      .subscribe(
        (val: UserAuthorization[]) => {
          //this.chronologyForm.reset();
          this.users.clear();
          //this.addChronology();
          if(val){
            this.createNestedFields(val);
          }

        }
      )
  }

  save() {
    const results = this.users.getRawValue();
    results.forEach(element => {
      if(element.authorization == true) {
        element.authorization = 1;
      }
      else {
        element.authorization = 0;
      }
    });
    // console.log(results);
    this.connectServerService.postRequest<ApiResponse<{}>>('changeAccessUsers', {
      listUsersAuthorization: results
    }, Connect.urlServerLaraApi).subscribe(
      (esito: ApiResponse<{}>) => {
        this.popupService.popupDialog(esito);
        this.getUserInfo();
      }
    )
  }

}
