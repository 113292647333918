import { CommonModule } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { PopupDialogService } from '../../../services/popup-dialog.service';
import { ConnectServerService } from '../../../services/connect-server.service';
import { Connect } from '../../../classes/connect';
import { ApiResponse } from '../../../interfaces/api-response';
import { UserAuthorization } from '../../../interfaces/user';

interface Authorizations {
  idcomponent: number;
  component_name: string;
  page_name: string;
  path: string;
  authorization: number;
  readonly: number;
  idpermission: number;
  type: number;
  view_always: number;
}
interface User {
  id: number;
  name: string;
  email: string;
}

@Component({
  selector: 'app-pages',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatTableModule,
  ],
  templateUrl: './pages.component.html',
  styleUrl: './pages.component.scss'
})
export class PagesComponent {
  displayedColumns: string[] = ['idComponent', 'component', 'page', 'path', 'authorizations', 'readonly'];

  usersList: UserAuthorization[] = [];

  userForm: FormGroup = this.fb.group({
    id: [0],
    name: [null]
  });

  authorizationsForm: FormGroup = this.fb.group({
    authorizations: this.fb.array([])
  });

  constructor(private fb: FormBuilder,
    private popupService: PopupDialogService,
    private connectServerService: ConnectServerService) { }

  ngOnInit(): void {
    this.userForm.valueChanges.subscribe(() => {
      this.selectedUser(); // Chiama la funzione ogni volta che il form cambia
    });
    this.connectServerService.getRequest('listUsers', {}, Connect.urlServerLaraApi)
      .subscribe(
        (val: UserAuthorization[]) => {
          this.usersList = val;
        });
  }

  private popolateAuthorizationsForm() {
    this.authorizations.clear(); // Svuota il form prima di popolarlo di nuovo
    const iduser = this.userForm.get('id')?.value;
    if (iduser && iduser > 0) {
      this.connectServerService.getRequest('listPagesPermissions', {
        iduser: iduser
      }, Connect.urlServerLaraApi)
        .subscribe((val) => {
          const listPages: Authorizations[] = val;
          // console.log(val);
          listPages.forEach(item => {
            const field_disabled = item.view_always === 1 ? true : false;
            const element = this.fb.group({
              idcomponent: [item.idcomponent],
              component_name: [item.component_name],
              page_name: [item.page_name],
              path: [item.path],
              authorization: [{ value: item.authorization, disabled: field_disabled }],
              readonly: [{ value: item.readonly, disabled: field_disabled }],
              idpermission: [item.idpermission],
              type: [item.type],
              view_always: [item.view_always],
            })
            this.authorizations.push(element);
          });
        });
    }
  }

  get authorizations() {
    return this.authorizationsForm.get('authorizations') as FormArray;
  }

  selectedUser() {
    this.popolateAuthorizationsForm()
  }

  save() {
    this.authorizations.controls.forEach(element => {
      if (element.get('authorization')?.value == true) {
        element.get('authorization')?.setValue(1);
      }
      else if (element.get('authorization')?.value == false) {
        element.get('authorization')?.setValue(0);
      }

      if (element.get('readonly')?.value == true) {
        element.get('readonly')?.setValue(1);
      }
      else if (element.get('readonly')?.value == false) {
        element.get('readonly')?.setValue(0);
      }
    });
    const iduser = this.userForm.get('id')?.value;
    const authorizations = this.authorizationsForm.getRawValue();
    this.connectServerService.postRequest<ApiResponse<{}>>('permissionsPagesUser', {
      iduser: iduser,
      listPermissions: authorizations.authorizations
    }, Connect.urlServerLaraApi).subscribe(
      (esito: ApiResponse<{}>) => {
        this.popupService.popupDialog(esito);
        this.popolateAuthorizationsForm();
      }
    )
    // console.log(this.authorizationsForm.getRawValue())
  }

}
