import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ConnectServerService } from '../../../services/connect-server.service';
import { Connect } from '../../../classes/connect';
import { ApiResponse } from '../../../interfaces/api-response';
import { Category, SubCategory } from '../../../interfaces/article';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-inventory-historical',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatTooltipModule
  ],
  templateUrl: './inventory-historical.component.html',
  styleUrl: './inventory-historical.component.scss'
})
export class InventoryHistoricalComponent {

  inventoryHistorical: any[] = [];

  categoryFilter = new FormControl<number | null>(null);
  subcategoryFilter = new FormControl<number | null>(null);
  categories: Category[] = [];
  subcategories: SubCategory[] = [];

  constructor(private connectServerService: ConnectServerService) {
    this.formLogic();
    this.getInventoryFromServer(false);
    this.getCategories();
  }

  formLogic() {
    if(this.categoryFilter.value == null) {
      this.subcategoryFilter.setValue(null);
      this.subcategoryFilter.disable();
    }
    else {
      this.subcategoryFilter.enable();
    }
  }

  getSubcategories() {
    this.connectServerService.getRequest<ApiResponse<{ subCategories: SubCategory[] }>>
      ('subCategories', { id: this.categoryFilter.value })
      .subscribe(
        (val: ApiResponse<{ subCategories: SubCategory[] }>) => {
          if (val.data && val.data.subCategories) {
            this.subcategories = val.data.subCategories;
            this.formLogic();
          }
        }
      )
  }

  getInventoryFromServer(isSubcategory: boolean) {
    const idcategory = this.categoryFilter.value;
    let idsubcategory: number | null = null;
    if(isSubcategory == true) {
      idsubcategory = this.subcategoryFilter.value;
    }

    this.connectServerService.getRequest('inventoryHistorical', { idcategory: idcategory, idsubcategory: idsubcategory })
      .subscribe(
        (val: any) => {
          this.inventoryHistorical = val.data.historicalInventory;
          // Aggiungi dinamicamente la proprietà isOpen a ogni elemento
          this.inventoryHistorical.forEach(element => {
            element.isOpen = true; // Imposta il valore predefinito a true
          });
        }
      )
  }

  private getCategories() {
    this.connectServerService.getRequest('categories', {})
      .subscribe(
        (val: ApiResponse<{ categories: Category[] }>) => {
          this.categories = val.data.categories;
          this.formLogic();
        }
      )
  }

  openClose(element: any) {
    element.isOpen = !element.isOpen;
  }

  ngAfterViewChecked() {
    this.adjustContentHeight();
  }

  private adjustContentHeight() {
    const elements = document.querySelectorAll('.content-wrapper');
    elements.forEach((element: any) => {
      if (element.classList.contains('open')) {
        element.style.height = `${element.scrollHeight}px`;
      } else {
        element.style.height = '0';
      }
    });
  }

}
