<div class="mt-2 mb-2">
    <mat-accordion>
        <mat-expansion-panel [expanded]="panelOpenState()" (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
            <mat-expansion-panel-header class="borderBottom">
                <mat-panel-title><span class="title">Oggetto</span></mat-panel-title>
                <mat-panel-description class="justify-content-end">
                    <i *ngIf="!saved" class="bi bi-exclamation-circle-fill" style="color: red; font-size: large;"></i>
                    <i *ngIf="saved" class="bi bi-check-circle-fill" style="color: green; font-size: large;"></i>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="container-fluid p-1 p-lg-2">
                <form [formGroup]="objectForm">
                    <div class="row justify-content-start align-items-center">
                        <div class="col-12 mt-2 text-center">
                            <span class="subTitle">Oggetto</span>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                            <label for="definition_level1" class="form-label" style="font-weight: bold;">
                                Definizione<sup>*</sup>
                            </label>
                            <select class="form-select mb-2" aria-label="Default select example"
                                formControlName="definition_level1"
                                [class.is-invalid]="objectForm.get('definition_level1')?.invalid && (submitted || objectForm.get('definition_level1')?.dirty)">
                                <option [ngValue]="null" disabled>Seleziona una categoria</option>
                                <option *ngFor="let option of listObject" [ngValue]="option.id"
                                    class="form-control">
                                    {{option.definition}}
                                </option>
                            </select>
                        </div>

                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                            <label for="definition_level2" class="form-label" style="font-weight: bold;">
                                Tipologia
                            </label>
                            <select class="form-select mb-2" aria-label="Default select example"
                                formControlName="definition_level2">
                                <option [ngValue]="null">Non specificato</option>
                                <option *ngFor="let option of optionsLevel2" [ngValue]="option.id"
                                    class="form-control">
                                    {{option.definition}}
                                </option>
                            </select>
                        </div>

                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                            <label for="definition_level3" class="form-label" style="font-weight: bold;">
                                Identificazione
                            </label>
                            <select class="form-select mb-2" aria-label="Default select example"
                                formControlName="definition_level3">
                                <option [ngValue]="null">Non specificato</option>
                                <option *ngFor="let option of optionsLevel3" [ngValue]="option.id"
                                    class="form-control">
                                    {{option.definition}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="row justify-content-start align-items-center borderTop mt-3">
                        <div class="col-12 mt-2 text-center">
                            <span class="subTitle">Quantità</span>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <label for="quantity" class="form-label" style="font-weight: bold;">
                                Quantità<sup>*</sup>
                            </label>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Quantità dell'articolo"
                                    id="quantity" formControlName="quantity" (input)="isCorrectQuantity()"
                                    [class.is-invalid]="objectForm.get('quantity')?.invalid && 
                                        (submitted || objectForm.get('quantity')?.dirty)">
                            </div>
                            <div *ngIf="objectForm.get('quantity')?.errors?.['maxlength'] && objectForm.get('quantity')?.dirty">
                                <span style="font-size: x-small; color: red;">Max 3 caratteri numerici</span>
                            </div>
                            <div *ngIf="objectForm.get('quantity')?.errors?.['letters'] && objectForm.get('quantity')?.dirty">
                                <span style="font-size: x-small; color: red;">Non sono ammessi caratteri non numerici</span>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-start borderTop mt-3">
                        <div class="col-12 mt-2 text-center">
                            <span class="subTitle">Soggetto</span>
                        </div>

                        <div class="col-12 col-md-6 mt-3">
                            <label for="identification" class="form-label" style="font-weight: bold;">
                                Identificazione<sup>*</sup>
                            </label>
                            <div formArrayName="identification">
                                <div *ngFor="let field of identificationFields.controls; let i = index" class="mb-1"
                                    [formGroupName]="i">
                                    <div class="input-group">
                                        <input type="text" class="form-control" formControlName="content"
                                            placeholder="Inserisci identificazione {{i+1}}"
                                            [class.is-invalid]="field.get('content')?.invalid && (field.get('content')?.dirty || submitted)">
                                        <button *ngIf="i != 0" class="btn btn-danger" type="button"
                                            (click)="removeIdentificationField(i)"
                                            [disabled]="identificationFields.length === 1">
                                            <i class="bi bi-trash"></i>
                                        </button>
                                    </div>
                                    <div *ngIf="identificationFields.at(i).errors?.['maxlength'] && identificationFields.at(i).dirty">
                                        <span style="font-size: x-small; color: red;">Max 250 caratteri</span>
                                    </div>
                                </div>
                            </div>

                            <button class="btn btn-outline-dark mt-1" type="button"
                                (click)="addIdentificationField()">
                                <span class="me-2">Aggiungi identificazione</span>
                                <i class="bi bi-plus"></i>
                            </button>
                        </div>

                        <div class="col-12 col-md-6 mt-3">
                            <label for="title" class="form-label" style="font-weight: bold;">
                                Titolo Opera<sup>*</sup>
                            </label>
                            <div formArrayName="title">
                                <div *ngFor="let title of titleFields.controls; let i = index" class="mb-1"
                                [formGroupName]="i">
                                    <div class="input-group">
                                        <input type="text" class="form-control" formControlName="content"
                                            placeholder="Inserisci titolo {{i+1}}"
                                            [class.is-invalid]="title.get('content')?.invalid && (title.get('content')?.dirty || submitted)">
                                        <button *ngIf="i != 0" class="btn btn-danger" type="button"
                                            (click)="removeTitleField(i)"
                                            [disabled]="titleFields.length === 1">
                                            <i class="bi bi-trash"></i>
                                        </button>
                                    </div>
                                    <div *ngIf="titleFields.at(i).errors?.['maxlength'] && titleFields.at(i).dirty">
                                        <span style="font-size: x-small; color: red;">Max 250 caratteri</span>
                                    </div>
                                </div>
                            </div>

                            <button class="btn btn-outline-dark mt-1" type="button"
                                (click)="addTitleField()">
                                <span class="me-2">Aggiungi titolo</span>
                                <i class="bi bi-plus"></i>
                            </button>
                        </div>
                    </div>
                </form>

                <div class="row justify-content-center mt-4 borderTop">
                    <div *ngIf="objectForm.invalid && submitted" class="col-12 mt-2 text-center">
                        <small>Compilare correttamente tutti i campi obbligatori per abilitare il
                            salvataggio
                        </small>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mt-1 text-center">
                        <button class="btn btn-outline-dark"
                            (click)="save()">Salva</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>