import { Component, inject, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CardComponent } from "../../../../components/card/card.component";
import { CommonModule } from '@angular/common';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { BehaviorSubject, debounceTime, filter, map, Observable, startWith, switchMap } from 'rxjs';
import { SearchAutocompleteService } from '../../../../services/search-autocomplete.service';
import { Article } from '../../../../interfaces/article';
import { ConnectServerService } from '../../../../services/connect-server.service';
import { Connect } from '../../../../classes/connect';
import { Card } from '../../../../interfaces/card';
import { PdfThumbnailService } from '../../../../services/pdf-thumbnail.service';

function autocompleteValidator(): (control: AbstractControl) => ValidationErrors | null {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    // Se il valore è vuoto (null o stringa vuota), è considerato valido
    if (value === '' || value === null) {
      return null;
    }

    // Se il valore è un oggetto valido con una proprietà `id`, è considerato valido
    if (typeof value === 'object' && 'id' in value) {
      return null;
    }
    return { invalidAutocomplete: true }; // Errore
  };
}
@Component({
  selector: 'app-card-view-popup',
  standalone: true,
  imports: [
    CardComponent,
    CommonModule,
    MatAutocompleteModule,
    ReactiveFormsModule
  ],
  templateUrl: './card-view-popup.component.html',
  styleUrl: './card-view-popup.component.scss'
})
export class CardViewPopupComponent implements OnInit {

  thumbnails: { [key: string]: string } = {};

  cards: Card[] = [];
  // filteredArticles$: Observable<Article[]>[] = [];
  filteredArticles$: BehaviorSubject<Article[]>[] = [];
  readonly connectServerService = inject(ConnectServerService);
  cardsForm = new FormGroup({
    card1: new FormControl<Card | null>(null, [autocompleteValidator()]),
    card2: new FormControl<Card | null>(null, [autocompleteValidator()]),
    card3: new FormControl<Card | null>(null, [autocompleteValidator()]),
    card4: new FormControl<Card | null>(null, [autocompleteValidator()]),
  })

  private readonly searchAutocompleteService = inject(SearchAutocompleteService);
  urlServerLaraApiMultimedia: string = Connect.urlServerLaraApiMultimedia;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { cards: Card[] },
    private dialogRef: MatDialogRef<CardViewPopupComponent>, private pdfThumbnailService: PdfThumbnailService) {
      this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.searchArticles();
    this.getInfo();
  }

  async generatePdfThumbnails(): Promise<void> {
    for (const file of this.cards) {
      if (file.src && file.ext === 'pdf') {
        const thumbnail = await this.pdfThumbnailService.generateThumbnail(this.urlServerLaraApiMultimedia+file.src);
        this.thumbnails[file.src!] = thumbnail;
      }
    }
  }

  private getInfo(){
    this.connectServerService.getRequest('cards', {})
      .subscribe((val: Card[]) => {
        const array_cards = val;
        this.cards = this.createCards(array_cards);
        array_cards.forEach(
          (card) => {
            this.cardsForm.get('card' + card.sort)?.setValue(card);
          })
      })
  }
  closeDialog(value: number): void {
    this.dialogRef.close(value);
  }

  private searchArticles() {
    // ricerca
    const cardNames = ['card1', 'card2', 'card3', 'card4'];

    cardNames.forEach((cardName, index) => {
      const cardControl = this.cardsForm.get(cardName);

      if (cardControl) {
        const subject = new BehaviorSubject<Article[]>([]);
        // Aggiorna il BehaviorSubject con i dati dell'autocomplete
        cardControl.valueChanges.pipe(
          startWith(''),
          map(value => (typeof value === 'string' ? value : value?.title || '')),
          filter(value => value.length > 0),
          debounceTime(600),
          switchMap((value: string) =>
            value ? this.searchAutocompleteService.getArticles(value) : [])
        ).subscribe(articles => {
          subject.next(articles); // Aggiorna i dati nel BehaviorSubject
        });

        // Salva il BehaviorSubject nell'array
        this.filteredArticles$[index] = subject;
        // Aggiungi un listener per impostare l'oggetto completo se selezionato
        cardControl.valueChanges.subscribe(value => {
          if (value === '') {
            // Se il valore è una stringa vuota, rimuovi gli errori
            cardControl.setErrors(null);
          } else if (typeof value === 'string') {
            // L'utente sta digitando, quindi invalidiamo se necessario
            const matchingArticle = this.filteredArticles$[index]?.getValue()?.find(article => article.title === value);
            if (matchingArticle) {
              cardControl.setValue(matchingArticle, { emitEvent: false });
              cardControl.setErrors(null); // Rimuovi gli errori
            } else {
              cardControl.setErrors({ invalidAutocomplete: true });
            }
          } else if (value) {
            cardControl.setErrors(null); // Reset degli errori se l'oggetto è valido
          }
        });
      }
    });
  }

  displayArticleName(article?: Article): string {
    return article ? article.title : '';
  }

  saveCards() {

    if (this.cardsForm.valid) {
      const rawValues = this.cardsForm.getRawValue();
      // Trasforma i valori
      const transformedValues = this.transformFormValues(rawValues);
      this.connectServerService.postRequest('updateCards',
        {
          cards: transformedValues
        })
        .subscribe((val: any) => {
          // this.closeDialog(1);
          this.getInfo();
        })
    } else {
      alert('Compila correttamente per salvare.')
      // console.log('Form non valido!', this.cardsForm.errors);
    }

  }

  private transformFormValues(formValues: { [key: string]: any }): { [key: string]: any } {
    const transformedValues: { [key: string]: any } = {};

    Object.keys(formValues).forEach(key => {
      const value = formValues[key];

      if (value === '' || value === null) {
        // Se il valore è una stringa vuota o null, imposta null
        transformedValues[key] = null;
      } else if (typeof value === 'object' && 'id' in value) {
        // Se il valore è un oggetto valido, prendi solo l'id
        transformedValues[key] = value.id;
      } else {
        // Qualsiasi altro caso (opzionale, se necessario gestirlo)
        transformedValues[key] = null;
      }
    });

    return transformedValues;
  }

  private createCards(array_cardserver: Card[]): Card[] {
    // Numero totale di card richiesto
    const totalCards = 4;
    // Ordina gli oggetti esistenti per sort
    const sortedCards = array_cardserver
      .filter(card => card.sort >= 1 && card.sort <= totalCards) // Filtra sort validi
      .sort((a, b) => a.sort - b.sort);
    // Array di card finali
    const finalCards: Card[] = [];
    for (let i = 1; i <= totalCards; i++) {
      // Trova la card con sort corrispondente
      const card = sortedCards.find(c => c.sort === i);

      if (card) {
        // Se la card esiste, aggiungila
        finalCards.push(card);
      } else {
        // Se non esiste, aggiungi una card di default
        finalCards.push({
          description: '',
          src: '',
          title: `Default Card ${i}`,
          id: 0,
          sort: i,
          ext: ''
        });
      }
    }
    return finalCards;
  }

}
