import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { CardComponent } from '../../components/card/card.component';
import { ConnectServerService } from '../../services/connect-server.service';
import { Connect } from '../../classes/connect';
import { ApiResponse } from '../../interfaces/api-response';
import { Carousel } from '../../interfaces/home';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { CardViewPopupComponent } from './components/card-view-popup/card-view-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../../services/authorization.service';
import { Card } from '../../interfaces/card';
import { PdfThumbnailService } from '../../services/pdf-thumbnail.service';

declare var bootstrap: any;

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  @ViewChild('myCarousel') carouselElement!: ElementRef;
  canEditArticle$: Observable<boolean>;
  thumbnails: { [key: string]: string } = {};
  search = new FormControl<string | null>(null);
  choosenArchive: { name: string, id: number } = { name: 'Archivio Storico', id: 1 };
  carousel: Carousel[] = [
    { title_img: 'Misericordia Sede', folder: 'carousel-home', name: 'assets/img/carousel2.jpg', src: '' },
    { title_img: 'Affresco', folder: 'carousel-home', name: 'assets/img/carousel1.jpg', src: '' },
    { title_img: 'Statua', folder: 'carousel-home', name: 'assets/img/carousel3.jpg', src: '' }
  ];

  urlServerLaraApiMultimedia = Connect.urlServerLaraApiMultimedia;

  cards: Card[] = [];
  //img: any[];
  query: string = '';

  constructor(private connectService: ConnectServerService, private router: Router, public authService: AuthService,
    private dialog: MatDialog, private authorizationService: AuthorizationService, private pdfThumbnailService: PdfThumbnailService) {
    // Usa il servizio per verificare se l'utente ha il permesso di "update_article_historical"
    this.canEditArticle$ = this.authorizationService.hasAuthorization('update_article_historical');
    this.serverRequest();
    //getHomeSlides()
  }

  ngAfterViewInit(): void {
    // Inizializza il carosello
    const carousel = new bootstrap.Carousel(this.carouselElement.nativeElement, {
      interval: 3000,
      ride: 'carousel',
      keyboard: true,
      pause: false
    });
  }

  async generatePdfThumbnails(): Promise<void> {
    for (const file of this.cards) {
      if (file.src && file.ext === 'pdf') {
        const thumbnail = await this.pdfThumbnailService.generateThumbnail(this.urlServerLaraApiMultimedia+file.src);
        this.thumbnails[file.src!] = thumbnail;
      }
    }
  }

  searchArchive() {
    // this.router.navigate(['archivio', { queryParams: { text: this.search.value}}]);
    if (this.choosenArchive.id == 1) {
      this.router.navigate(['/archivioStorico'], { queryParams: { text: this.search.value } });
    }
    else {
      this.router.navigate(['/archivioArtistico'], { queryParams: { text: this.search.value } });
    }
  }

  private serverRequest() {
    this.connectService.getRequest('home', {}).
      subscribe((val: ApiResponse<{ cards: Card[], carousel: Carousel[] }>) => {
        // console.log("data: ", val.data)
        // if (val.data && val.data.carousel) {
        //IMMAGINI SONO GIA' IN ASSETS
        //this.carousel = val.data.carousel;
        // }
        if (val.data && val.data.cards) {
          this.cards = val.data.cards;
          this.generatePdfThumbnails();
        }

      });
  }

  submit(query: string): void {
    this.query = query;
  }

  changeArchive() {
    if (this.choosenArchive.name == 'Archivio Storico') {
      this.choosenArchive.name = 'Archivio Artistico'
      this.choosenArchive.id = 2;
    }
    else {
      this.choosenArchive.name = 'Archivio Storico'
      this.choosenArchive.id = 1;
    }
    // console.log(this.choosenArchive)
  }

  openPopup() {
    const dialogRef = this.dialog.open(CardViewPopupComponent, {
      data: { cards: this.cards },
      panelClass: 'custom-modal-class',
      maxWidth: '90vw',
      maxHeight: '84vh',
      width: '80vw',
      height: 'auto',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(
      (result: number) => {
        if (result === 1) {
          this.serverRequest();
        }
      });
  }

}
