<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-12">
            <div class="custom-card row align-items-center custom-shadow"
            [ngClass]="'color' + counter"
                 (click)="navigate(obj_card.id)"
                 (mouseenter)="changeCursor('pointer')"
                 (mouseleave)="changeCursor('auto')">
                <div class="custom-card-image col-5">
                    <img *ngIf="obj_card.ext == 'pdf'; else imageBlock" [src]="thumbnails[obj_card.src!] || 'assets/img/pdfCover.png'"
                    oncontextmenu="return false;">
                    <ng-template #imageBlock>
                        <img [src]="urlServerLaraApiMultimedia + obj_card.src" alt="{{obj_card.title}}"
                        oncontextmenu="return false;">
                    </ng-template>
                </div>
                <div class="custom-card-content col-7 text-center">
                    <h4 class="custom-card-title">{{obj_card.title}}</h4>
                </div>
            </div>
        </div>
    </div>
</div>
