import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Connect } from '../../classes/connect';
import { Card } from '../../interfaces/card';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss'
})
export class CardComponent implements OnInit {

  urlServerLaraApiMultimedia = Connect.urlServerLaraApiMultimedia;

  @Input() obj_card!: Card;
  @Input() thumbnails: { [key: string]: string } = {}; 
  @Input() counter: number = 1;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  navigate(id: number) {
    this.router.navigate(['archivioStorico/scheda', id])
  }

  changeCursor(cursorType: string) {
    document.body.style.cursor = cursorType;
  }



}
