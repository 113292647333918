import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FolderService } from '../services/folder.service';
import { CommonModule } from '@angular/common';
import { ApiResponse } from '../../../../interfaces/api-response';

@Component({
  selector: 'app-folder',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './folder.component.html',
  styleUrl: './folder.component.scss'
})
export class FolderComponent {
  private readonly folderService = inject(FolderService);
  @Input() folderName!: string;
  @Input() path: string = '';
  @Output() saveArticle_folder: EventEmitter<{ path: string }> = new EventEmitter<{ path: string }>();
  // 0 temp, 1 definitivo
  @Input() archive_type: number = 0;
  contentsLoaded: boolean = false;
  showContents: boolean = false;
  folders: string[] = [];
  files: any[] = [];

  constructor() { }

  toggleContents() {
    this.showContents = !this.showContents;
    if (this.showContents && !this.contentsLoaded) {
      this.loadContents();
    }
  }

  loadContents() {
    const newPath = this.path ? `${this.path}/${this.folderName}` : this.folderName;
    this.folderService.getFolderContents(this.archive_type, newPath).subscribe(
      (data) => {
        this.folders = data.folders;
        this.files = data.files;
        this.contentsLoaded = true;
      }
    );
  }

  copyAndDeleteFolder(path: string) {
    this.saveArticle_folder.emit({ path: path });
  }
  saveArticle(event: { path: string }) {
    // Emetti l'evento al componente padre
    this.saveArticle_folder.emit(event);
  }
}
