<div class="mt-2 mb-2">
    <mat-accordion>
        <mat-expansion-panel [expanded]="panelOpenState()" (opened)="panelOpenState.set(true)"
            (closed)="panelOpenState.set(false)">
            <mat-expansion-panel-header class="borderBottom">
                <mat-panel-title><span class="title">Informazioni Generali</span></mat-panel-title>
                <mat-panel-description class="justify-content-end">
                    <i *ngIf="!saved" class="bi bi-exclamation-circle-fill" style="color: red; font-size: large;"></i>
                    <i *ngIf="saved" class="bi bi-check-circle-fill" style="color: green; font-size: large;"></i>
                </mat-panel-description>

            </mat-expansion-panel-header>

            <div class="container-fluid p-1 p-lg-2">
                <form [formGroup]="generalForm">
                    <div class="row justify-content-start align-items-center">
                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                            <label for="title" class="form-label" style="font-weight: bold;">
                                Titolo<sup>*</sup>
                            </label>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Titolo della scheda" id="title"
                                    formControlName="title"
                                    [class.is-invalid]="generalForm.get('title')?.invalid &&
                                        (submitted || generalForm.get('title')?.dirty)">
                            </div>
                            <!-- <div
                                *ngIf="generalForm.get('title')?.invalid && generalForm.get('title')?.dirty">
                                <span style="font-size: x-small; color: red;">Max 250 caratteri</span>
                            </div> -->
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                            <label for="storage_place" class="form-label" style="font-weight: bold;">
                                Luogo di conservazione<sup>*</sup>
                            </label>
                            <div class="input-group" style="height: 40px;">
                                <input type="text" class="form-control" placeholder="Luogo" id="storage_place"
                                    formControlName="storage_place"
                                    [class.is-invalid]="generalForm.get('storage_place')?.invalid &&
                                        (submitted || generalForm.get('storage_place')?.dirty)">
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                            <label for="article_type" class="form-label" style="font-weight: bold;">
                                Tipo di scheda<sup>*</sup>
                            </label>
                            <select class="form-select mb-2" aria-label="Default select example"
                                formControlName="article_type"
                                [class.is-invalid]="generalForm.get('article_type')?.invalid &&
                                        (submitted || generalForm.get('article_type')?.dirty)">
                                <option [ngValue]="null" disabled>Seleziona una categoria</option>
                                <option *ngFor="let option of listType" [ngValue]="option.id" class="form-control">
                                    {{option.definition}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="row justify-content-start align-items-center">
                        <div class="col-6 col-md-3 mt-3">
                            <label for="floor" class="form-label" style="font-weight: bold;">
                                Piano<sup>*</sup>
                            </label>
                            <div class="input-group" style="height: 40px;">
                                <input type="text" class="form-control" placeholder="Piano edificio" id="floor"
                                    formControlName="floor"
                                    [class.is-invalid]="generalForm.get('floor')?.invalid &&
                                        (submitted || generalForm.get('floor')?.dirty)">
                            </div>
                        </div>

                        <div class="col-6 col-md-3 mt-3">
                            <label for="room" class="form-label" style="font-weight: bold;">
                                Stanza<sup>*</sup>
                            </label>
                            <div class="input-group" style="height: 40px;">
                                <input type="text" class="form-control" placeholder="" id="room" formControlName="room"
                                [class.is-invalid]="generalForm.get('room')?.invalid &&
                                        (submitted || generalForm.get('room')?.dirty)">
                            </div>
                        </div>

                        <div class="col-4 col-md-2 mt-3">
                            <label for="rack" class="form-label" style="font-weight: bold;">
                                Scaffale<span class="d-none d-lg-inline">/Armadio</span><sup>*</sup>
                            </label>
                            <div class="input-group" style="height: 40px;">
                                <input type="text" class="form-control" placeholder="" id="rack" formControlName="rack"
                                [class.is-invalid]="generalForm.get('rack')?.invalid &&
                                        (submitted || generalForm.get('rack')?.dirty)">
                            </div>
                        </div>

                        <div class="col-4 col-md-2 mt-3">
                            <label for="shelf" class="form-label" style="font-weight: bold;">
                                Ripiano<sup>*</sup>
                            </label>
                            <div class="input-group" style="height: 40px;">
                                <input type="text" class="form-control" placeholder="" id="shelf" formControlName="shelf"
                                [class.is-invalid]="generalForm.get('shelf')?.invalid &&
                                        (submitted || generalForm.get('shelf')?.dirty)">
                            </div>
                        </div>

                        <div class="col-4 col-md-2 mt-3">
                            <label for="case" class="form-label" style="font-weight: bold;">
                                Contenitore<sup>*</sup>
                            </label>
                            <div class="input-group" style="height: 40px;">
                                <input type="text" class="form-control" placeholder="" id="case" formControlName="case"
                                [class.is-invalid]="generalForm.get('case')?.invalid &&
                                        (submitted || generalForm.get('case')?.dirty)">
                            </div>
                        </div>

                        <div class="col-12 col-sm-6 mt-3">
                            <label for="public" class="form-label" style="font-weight: bold;">Visibilità
                                Articolo</label><br>
                            <div class="form-check form-switch ms-1" style="margin-bottom: 1.6rem;">
                                <input class="form-check-input form-control" type="checkbox" role="switch" id="public"
                                    formControlName="public" checked style="cursor: pointer;"
                                    (change)="enableDisablePublication()">
                                <label class="form-check-label" for="public">Pubblico</label>
                            </div>
                        </div>
                        <div *ngIf="generalForm.get('public')!.value == 1" class="col-6">
                            <label for="public_year" class="form-label" style="font-weight: bold;">Anno
                                Visibilità
                            </label>
                            <div class="input-group mb-2" style="height: 38px;">
                                <input type="text" class="form-control" placeholder="Anno Pubblicazione"
                                    id="public_year" formControlName="public_year" (input)="isCorrectPublicYear()"
                                    [class.is-invalid]="generalForm.get('public_year')?.invalid &&
                                        (submitted || generalForm.get('public_year')?.dirty)">
                            </div>
                            <div
                                *ngIf="generalForm.get('public_year')?.invalid && generalForm.get('public_year')?.dirty">
                                <span style="font-size: x-small; color: red;">Campo numerico, range anni 2024 - 9999</span>
                            </div>
                        </div>
                    </div>

                    <!-- IMMAGINI -->
                    <div *ngIf="idarticle > 0" class="row justify-content-center align-items-center borderTop mt-3">
                        <div class="col-12 col-lg-6 text-center pt-2 mt-2">
                            <div class="row justify-content-center">
                                <div class="col-12 mt-1 carousel slide" id="carousel" *ngIf="files.length > 0"
                                    style="width: 100%;">
                                    <div class="carousel-inner">
                                        <div class="carousel-item" *ngFor="let file of files; let i = index"
                                            [class.active]="file.id == currentImg">
                                            <img *ngIf="file.ext != 'pdf'" [src]="urlServerLaraApiMultimedia + file.src"
                                                [alt]="file.title" [title]="file.title" class="coverImg">
                                            <img *ngIf="file.ext == 'pdf'" src="assets/img/pdfThumbnail.png"
                                                [alt]="file.title" class="coverImg" [title]="file.title">
                                        </div>
                                    </div>
                                    <a class="carousel-control-prev" role="button" data-bs-target="#carousel"
                                        data-bs-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" role="button" data-bs-target="#carousel"
                                        data-bs-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </a>
                                </div>
                            </div>
                            <div class="row justify-content-center" style="max-height: 500px; overflow-y: auto;">
                                <ng-container *ngFor="let file of files; let i = index">
                                    <div class="col-1 mb-4 mt-1" style="width: 80px; height: 80px;">
                                        <img *ngIf="file.ext != 'pdf'" [src]="urlServerLaraApiMultimedia + file.src"
                                            class="img-fluid thumbnailImg" [title]="file.title"
                                            (click)="visualizeImg(file.id)">
                                        <img *ngIf="file.ext == 'pdf'" src="assets/img/pdfThumbnail.png"
                                            class="img-fluid thumbnailImg" [title]="file.title"
                                            (click)="visualizeImg(file.id)">
                                        <i class="bi bi-trash hoverPointer" style="color: red; margin-right: 5px;"
                                            (click)="deleteImg(file.id)"></i>
                                        <i class="bi bi-star hoverPointer" *ngIf="file.cover === 0"
                                            style="color: green; margin-left: 5px;" (click)="changeCover(file.id)"></i>
                                        <i class="bi bi-star-fill hoverPointer" *ngIf="file.cover === 1"
                                            style="color: green; margin-left: 5px;"></i>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-12 col-md-8 col-lg-6 mt-3">
                                    <label for="fileUpload" class="btn btn-outline-dark">
                                        <i class="bi bi-upload"></i>
                                        <span style="margin-left: 10px;">Carica Immagine</span>
                                        <input type="file" id="fileUpload" style="display: none;"
                                            (change)="onFileSelected($event)" accept="*" multiple>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="row justify-content-center mt-4 borderTop">
                    <div *ngIf="generalForm.invalid && submitted" class="col-12 mt-2 text-center">
                        <small>
                            Compilare correttamente tutti i campi obbligatori per abilitare il
                            salvataggio
                        </small>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mt-1 text-center">
                        <button class="btn btn-outline-dark" (click)="save()">Salva</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
