<div class="container">
  <div class="row text-center justify-content-lg-center mt-5">
    <div class="col-12 col-sm-6 mt-4">
      <h3 class="h3">
        Importa Regioni, Province e Città
      </h3>
      <p>
        Il file l'ho preso da:
        <a href="https://www.istat.it/it/archivio/6789" target="_blank">https://www.istat.it/it/archivio/6789</a>.<br>
        Il file sulla prima riga ha l'intestazione, i campi usati sono alla colonna: 6, 10, 11, 14, 19 partendo da
        zero.
      </p>
      <input type="file" class="d-none" (change)="uploadFiles($event)" #fileUpload>
      <div class="file-upload">
        <button mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()">
          <mat-icon>attach_file</mat-icon>
        </button>
        Importa file Excel.
      </div>
    </div>
  </div>
</div>
