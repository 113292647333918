<div class="mt-2 mb-2">
    <mat-accordion>
        <mat-expansion-panel [expanded]="panelOpenState()" (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
            <mat-expansion-panel-header class="borderBottom">
                <mat-panel-title><span class="title">Conservazione</span></mat-panel-title>
                <mat-panel-description class="justify-content-end">
                    <i *ngIf="!saved" class="bi bi-exclamation-circle-fill" style="color: red; font-size: large;"></i>
                    <i *ngIf="saved" class="bi bi-check-circle-fill" style="color: green; font-size: large;"></i>
                </mat-panel-description>

            </mat-expansion-panel-header>

            <div class="container-fluid p-1 p-lg-2">
                <form [formGroup]="conservationForm">
                    <div class="row justify-content-start align-items-center">
                        <div class="col-12 mt-2 text-center">
                            <span class="subTitle">Ambito Culturale</span>
                        </div>

                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                            <label for="conservationStatus" class="form-label" style="font-weight: bold;">
                                Stato di conservazione<sup>*</sup>
                            </label>
                            <select class="form-select" aria-label="Default select example"
                                formControlName="conservationStatus"
                                [class.is-invalid]="conservationForm.get('conservationStatus')?.invalid && (submitted || conservationForm.get('conservationStatus')?.dirty)">
                                <option [ngValue]="null" disabled>Non specificato</option>
                                <option *ngFor="let option of statusOptions" [ngValue]="option.id"
                                    class="form-control">
                                    {{option.definition}}
                                </option>
                            </select>
                        </div>

                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                            <label for="specificIndications" class="form-label" style="font-weight: bold;">
                                Indicazioni specifiche
                            </label>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Titolo della scheda"
                                    id="specificIndications" formControlName="specificIndications"
                                    [class.is-invalid]="conservationForm.get('specificIndications')?.invalid && (conservationForm.get('specificIndications')?.dirty || submitted)">
                            </div>
                            <div
                                *ngIf="conservationForm.get('specificIndications')?.invalid && conservationForm.get('specificIndications')?.dirty">
                                <span style="font-size: x-small; color: red;">Max 500 caratteri</span>
                            </div>
                        </div>

                    </div>
                </form>

                <div class="row justify-content-center mt-4 borderTop">
                    <div *ngIf="conservationForm.invalid && submitted" class="col-12 mt-2 text-center">
                        <small>Compilare correttamente tutti i campi obbligatori per abilitare il
                            salvataggio
                        </small>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mt-1 text-center">
                        <button class="btn btn-outline-dark"
                            (click)="save()">Salva</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>