<div class="row justify-content-center align-items-center">
  <div class="col-12 text-center mb-1" style="border-bottom: 1px solid lightgray;">
    <span style="font-weight: bold; font-size: large;">
      Archivio {{archive_type == 0 ? 'Temporaneo' : 'Permanente'}}
      <button *ngIf="archive_type == 0" type="button" class="btn btn-outline-primary py-0" (click)="refresh()">
        <i class="bi bi-arrow-clockwise"></i>
      </button>
    </span>
  </div>
</div>

<div class="row">
  <ul class="list-unstyled">
    <!-- Loop per le cartelle di primo livello -->
    <app-folder [archive_type]="archive_type"
    (saveArticle_folder)="saveArticle($event)"
    *ngFor="let folder of folders" [folderName]="folder" [path]="path">
    </app-folder>

    <!-- Loop per i file di primo livello -->
    <li *ngFor="let file of files" [title]="file.modified">
      <i class="bi bi-document me-2"></i>
      <span class="folder">
        {{ file.name }} ({{ file.size }} MB)
      </span>
    </li>
  </ul>
</div>
