import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { SelectCulturalDefinition } from '../../interfaces/select-cultural-definition';
import { SelectOptions } from '../../interfaces/select-options';
import { Connect } from '../../../../classes/connect';
import { ApiResponse } from '../../../../interfaces/api-response';
import { ConnectServerService } from '../../../../services/connect-server.service';
import { CheckFormValidityService } from '../../../../services/check-form-validity.service';
import { PopupDialogService } from '../../../../services/popup-dialog.service';


@Component({
  selector: 'app-sources',
  standalone: true,
  imports: [
    MatExpansionModule,
    ReactiveFormsModule,
    CommonModule
  ],
  templateUrl: './sources.component.html',
  styleUrl: './sources.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SourcesComponent {

  readonly panelOpenState = signal(false);

  @Input() idarticle: number = 0;
  @Input() listCulturalDefinition: SelectCulturalDefinition | null = null;

  saved: boolean = false;
  submitted = false;

  sourcesForm: FormGroup;

  listOwner: SelectOptions[] = [];
  listType: SelectOptions[] = [];
  listGenre: SelectOptions[] = [];

  constructor(private fb: FormBuilder, private connectServerService: ConnectServerService,
    private popupDialogService: PopupDialogService, private checkFormValidityService: CheckFormValidityService) {
    this.sourcesForm = this.fb.group({
      picturesSources: this.fb.array([this.createPicturesSources()]),
      documentSources: this.fb.array([this.createDocumentSources()]),
      bibliography: this.fb.array([this.createBibliography()]),
      completeCitations: this.fb.array([this.createCompleteCitations()]),
    });
  }

  ngOnInit(): void {
    //TEMPORANEO
    this.panelOpenState.set(true);
  }

  // Funzione che crea un singolo gruppo di campi
  createPicturesSources(): FormGroup {
    return this.fb.group({
      id: [0],
      owner: [null, Validators.maxLength(250)],
      code: [null, [Validators.required, Validators.maxLength(250)]],
    });
  }

  createDocumentSources(): FormGroup {
    return this.fb.group({
      id: [0],
      type: [null, Validators.required],
      author: [null, Validators.maxLength(50)],
      denomination: [null, Validators.maxLength(250)],
      date: [null, Validators.maxLength(25)],
      paper: [null, Validators.maxLength(250)],
      archiveName: [null, Validators.maxLength(250)],
      position: [null, Validators.maxLength(50)],
    });
  }

  createBibliography(): FormGroup {
    return this.fb.group({
      id: [0],
      genre: [null, Validators.required],
      author: [null, Validators.maxLength(250)],
      year: [null, Validators.maxLength(10)],
      acronym: [null, Validators.maxLength(8)],
      vPpNn: [null, Validators.maxLength(50)],
      vTavvFigg: [null, Validators.maxLength(50)]
    });
  }

  createCompleteCitations(): FormGroup {
    return this.fb.group({
      id: [0],
      completeCitation: [null, Validators.maxLength(500)]
    })
  }

  // Restituisce il FormArray per una gestione più semplice
  get picturesSources(): FormArray {
    return this.sourcesForm.get('picturesSources') as FormArray;
  }

  get documentSources(): FormArray {
    return this.sourcesForm.get('documentSources') as FormArray;
  }

  get bibliography(): FormArray {
    return this.sourcesForm.get('bibliography') as FormArray;
  }

  get completeCitations(): FormArray {
    return this.sourcesForm.get('completeCitations') as FormArray;
  }

  // Aggiunge un nuovo gruppo di campi al FormArray
  addPicturesSources() {
    this.picturesSources.push(this.createPicturesSources());
  }

  addDocumentSources() {
    this.documentSources.push(this.createDocumentSources());
  }

  addBibliography() {
    this.bibliography.push(this.createBibliography());
  }

  addCompleteCitations() {
    this.completeCitations.push(this.createCompleteCitations());
  }

  // Rimuove un gruppo di campi dal FormArray, lasciando sempre almeno uno
  removePicturesSources(index: number) {
    if (this.picturesSources.length > 1) {
      this.picturesSources.removeAt(index);
    }
  }

  removeDocumentSources(index: number) {
    if (this.documentSources.length > 1) {
      this.documentSources.removeAt(index);
    }
  }

  removeBibliography(index: number) {
    if (this.bibliography.length > 1) {
      this.bibliography.removeAt(index);
    }
  }

  removeCompleteCitations(index: number) {
    if (this.completeCitations.length > 1) {
      this.completeCitations.removeAt(index);
    }
  }

  private getSourcesInfo() {
    this.connectServerService.getRequest('', { idarticle: this.idarticle })
      .subscribe(
        (val: ApiResponse<{ sourcesForm: any }>) => {
          this.sourcesForm.patchValue(val.data.sourcesForm);
          this.saved = this.checkFormValidityService.areAllRequiredFieldsFilled(this.sourcesForm);
          if(this.saved) {
            this.panelOpenState.set(false);
          }
          else {
            this.panelOpenState.set(true);
          }
        }
      )
  }

  private setSourcesInfo() {
    const formValues = this.sourcesForm.getRawValue();
    this.connectServerService.postRequest('',
      { idarticle: this.idarticle, infoSources: formValues })
      .subscribe(
        (val: ApiResponse<null>) => {
          this.popupDialogService.popupDialog(val);
          this.getSourcesInfo();
        }
      )
  }

  save() {
    this.submitted = true;
    if(this.sourcesForm.valid) {
      this.setSourcesInfo();
    }
  }

}
