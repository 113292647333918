import { Component, Inject } from '@angular/core';
import { ApiResponse, ObjButtonPopup } from '../../../interfaces/api-response';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'app-popup',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule
  ],
  templateUrl: './popup-import-with-title.component.html',
  styleUrl: './popup-import-with-title.component.scss'
})
export class PopupImportWithTitleComponent {

  title = '???';
  descrizione = ':(';
  buttonStylePopup = 'btn btn-warning';
  buttonClosePopup = 'btn btn-primary'
  bgStylePopup = 'bg-primary bg-opacity-25 border-bottom border-1 border-primary';
  label_buttonClose = 'Annulla';
  label_buttonAction = 'Ok';
  submitted = false;

  obj_buttonAction!: ObjButtonPopup;
  obj_buttonClose!: ObjButtonPopup;

  titleForm = new FormGroup({
    articleTitle: new FormControl<string | null>(null, Validators.required)
  })

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PopupImportWithTitleComponent>) {
    this.setValuePopup(data.obj);
  }

  setValuePopup(obj_request: any) {
    this.title = obj_request.title;
    this.descrizione = obj_request.message;
    this.assignTitle(obj_request.data.path);
  }

  private assignTitle(path: string) {
    const lastPart = path.split('/').pop();
    if(lastPart != undefined) {
      this.titleForm.get('articleTitle')?.setValue(lastPart!);
    }
  }

  buttonClose() {
    this.dialogRef.close();
  }
  buttonAction() {
    this.submitted = true;
    if (this.titleForm.valid) {
      this.dialogRef.close(this.titleForm.get('articleTitle')?.value);
    }
  }

}
