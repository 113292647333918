import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { PermissionsUser } from '../interfaces/permissions-user';
import { Store } from '@ngrx/store';
import { selectUserPermissions } from '../ngrx/user/user.selectors';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  private permissions$: Observable<PermissionsUser | null>;

  constructor(private store: Store) {
    // Ottiene i permessi dell'utente dallo store NgRx
    this.permissions$ = this.store.select(selectUserPermissions);
  }

  // Funzione per verificare se l'utente ha una certa autorizzazione
  hasAuthorization(authName: string): Observable<boolean> {
    return this.permissions$.pipe(
      map(permissions => {
        if (!permissions) return false;
        return permissions.functionality.some(auth => auth.name === authName && auth.readonly === 0);
      })
    );
  }
}
