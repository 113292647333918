import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Folder } from '../../../interfaces/folder';
import { ConnectServerService } from '../../../services/connect-server.service';
import { Connect } from '../../../classes/connect';
import { ApiResponse } from '../../../interfaces/api-response';
import { FolderComponent } from "./folder/folder.component";
import { FolderService } from './services/folder.service';

@Component({
  selector: 'app-tree-view',
  standalone: true,
  imports: [
    CommonModule,
    FolderComponent
  ],
  templateUrl: './tree-view.component.html',
  styleUrl: './tree-view.component.scss'
})
export class TreeViewComponent {
  folders: string[] = [];
  files: any[] = [];
  path: string = ''; // Percorso iniziale vuoto
  // 0 temp, 1 definitivo
  @Input() archive_type: number = 1;
  @Output() saveArticle_tree: EventEmitter<{ path: string }> = new EventEmitter<{ path: string }>();

  constructor(private folderService: FolderService) { }

  ngOnInit() {
    this.loadRootContents();
  }

  private loadRootContents() {
    this.folders = [];
    this.files = [];
    this.folderService.getFolderContents(this.archive_type, this.path).subscribe(
      (data) => {
        this.folders = data.folders;
        this.files = data.files;
      }
      // ,
      // (error) => {
      //   console.error('Errore nel caricamento della cartella radice', error);
      // }
    );
  }
  saveArticle(event: { path: string }) {
    this.saveArticle_tree.emit(event);
  }

  refresh(){
    this.loadRootContents();
  }
}
