<h2 mat-dialog-title [class]="bgStylePopup">{{title}}</h2>
<mat-dialog-content class="mt-4">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-12">
                {{descrizione}}
            </div>
        </div>
        <div [formGroup]="titleForm" class="row align-items-center">
            <div class="col-12 col-lg-6 mt-2">
                <label class="form-label" style="font-weight: bold;" for="articleTitle">
                    Titolo
                </label>
                <input class="form-control" type="text" id="articleTitle" formControlName="articleTitle"
                [class.is-invalid]="titleForm.invalid && (submitted || titleForm.dirty)">
                <div *ngIf="titleForm.invalid && (submitted || titleForm.dirty)" style="font-size: small; color: red;">
                    Il titolo non può essere vuoto
                </div>
            </div>
        </div>
        <div class="col-12 mt-2">
            <span>Esempio titolo per salvare anche la data:</span><br>
            <span>- YYYY nome</span><br>
            <span>- YYYY-YYYY nome</span><br>
            <span>- YYYYMMDD nome</span>
        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions class="justify-content-end">
  <!-- <button mat-button mat-dialog-close [class]="buttonStylePopup">Chiudi</button> -->
  <button mat-button (click)="buttonAction()"
    [class]="buttonStylePopup" class="me-2">Copia e cancella</button>
  <button mat-button (click)="buttonClose()" [class]="buttonClosePopup">Chiudi</button>
</mat-dialog-actions>
