import { Component, Inject, ViewChild } from '@angular/core';
import { Connect } from '../../../../classes/connect';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ArticleFile } from '../../../../interfaces/image';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-pdf-display',
  standalone: true,
  imports: [],
  templateUrl: './pdf-display.component.html',
  styleUrl: './pdf-display.component.scss'
})
export class PdfDisplayComponent {

  fileId: number = 0;
  extension: string = '';
  currentSrc: SafeResourceUrl | null = null;
  urlServerLaraApiMultimedia = Connect.urlServerLaraApiMultimedia;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { article: ArticleFile },
    private dialogRef: MatDialogRef<PdfDisplayComponent>, private sanitizer: DomSanitizer) {
    //this.isImage = this.checkIfImage(data);

    this.currentSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.urlServerLaraApiMultimedia + data.article.src
    );
    this.extension = data.article.ext;
    this.fileId = data.article.id;
  }


  closeDialog(): void {
    this.dialogRef.close();
  }

}
