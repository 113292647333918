import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Article, City } from '../interfaces/article';
import { Connect } from '../classes/connect';
import { ConnectServerService } from './connect-server.service';
import { ApiResponse } from '../interfaces/api-response';

@Injectable({
  providedIn: 'root'
})
export class SearchAutocompleteService {

  constructor(private connectServerService: ConnectServerService) { }

  getCity(val: string): Observable<City[]> {
    return this.connectServerService.getRequest<ApiResponse<{ city: City[] }>>('cities',
      {
        query: val
      }, Connect.urlServerLaraApi).pipe(
        map(response => response.data.cities)
      );
  }
  getArticles(val: string): Observable<Article[]> {
    return this.connectServerService.getRequest<ApiResponse<{ article: Article[] }>>('articlesVisible',
      {
        query: val
      }).pipe(
        map(response => response.data.articles)
      );
  }
}
