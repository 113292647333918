<li class="ms-4">
  <div class="folderElement">
    <span (click)="toggleContents()" style="cursor: pointer;">
      <i *ngIf="!showContents" class="bi bi-caret-right-fill me-2"></i>
      <i *ngIf="showContents" class="bi bi-caret-down-fill me-2"></i>
      <i class="bi bi-folder me-2"></i>
      <span class="folder">
        {{ folderName }}
      </span>
    </span>
    <button *ngIf="archive_type === 0" class="btn btn-link"
      (click)="copyAndDeleteFolder(path ? path + '/' + folderName : folderName)">
      <i class="bi bi-floppy2-fill me-2"></i>
    </button>

  </div>
  <ul *ngIf="showContents" class="list-unstyled">
    <!-- Loop per le sottocartelle -->
    <app-folder *ngFor="let subFolder of folders"
    [folderName]="subFolder"
      [path]="path ? path + '/' + folderName : folderName"
      [archive_type]="archive_type"
      (saveArticle_folder)="saveArticle($event)">
    </app-folder>

    <!-- Loop per i file -->
    <li *ngFor="let file of files" class="ms-4" [title]="file.modified">
      <i class="bi bi-dash me-1"></i>
      <i class="bi bi-file-earmark-text"></i>
      {{ file.name }} ({{ file.size }} MB)
    </li>
  </ul>
</li>
