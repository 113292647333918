import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

//RESTITUZIONE DELLE OPZIONI DI FORM E INPUT

@Injectable({
  providedIn: 'root'
})
export class OptionsService {

  yearOptions: any[] = []

  constructor() {
    this.generateYears();
  }

  generateYears() {
    for (let i = 1800; i < 2024; i++) {
      this.yearOptions.push(i.toString());
    }
  }

  getYearOptions() {
    return this.yearOptions;
  }


  datePipe = new DatePipe('it');
  /**
   *
   * @param MatDatePicker
   * @returns  string di tipo yyyy-MM-dd
   */
  fromDateToString(obj_date: Date
  ): string {
    return obj_date.toISOString().split('T')[0]; // Trasforma la data in stringa 'yyyy-mm-dd'
  }
  /**
   *
   * @param date string con formato yyyy-MM-dd
   * @returns Date
   */
  fromStringToDate(date: string): Date | null {
    const [year, month, day] = date.split('-').map(Number);
  return new Date(year, month - 1, day); // Nota: i mesi in JavaScript sono indicizzati da 0
    // if (date && date.includes('-')) {
    //   const array_date = date.split('-');
    //   return new Date(Number(array_date[0]), (Number(array_date[1]) - 1), Number(array_date[2]));
    // } else {
    //   return null;
    // }

  }
}
